import React from 'react';

import { Container, Col, Row } from '@ott/grid';
import { l10nhtml } from '@ott/l10n';
import Timer from '@ott/timer';

import styles from './PaymentTimer.scss';

type PaymentTimerProps = {
  date: string | null;
  onOrderDie: () => void;
};

export const PaymentTimer: React.FC<PaymentTimerProps> = ({ date, onOrderDie }) => {
  if (!date) {
    return null;
  }
  const deadline = new Date(date);

  return (
    <div className={styles.paymentTimer}>
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col desktop={{ offset: 4, span: 8 }} mobile={4}>
            <div className={styles.timerWrapper}>
              <Timer
                className={styles.timer}
                showTimeLabel={false}
                showTimeIcon={false}
                to={deadline}
                deadlineHandler={onOrderDie}
              />
              &nbsp;
              {l10nhtml('surcharge.paymentTimer.timeLeft')}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
