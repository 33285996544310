import React from 'react';

import { LoyaltyMedia, LoyaltyVideo, LoyaltyVideoPoster } from '../../Loyalty';

import styles from './SolarCashMedia.scss';

export const SolarCashMedia = () => (
  <LoyaltyMedia>
    <div className={styles.container}>
      <LoyaltyVideo className={styles.video}>
        <source
          src="https://static.solartrip.com/mp4/index_page/loyalty/solar_cash.mp4"
          type="video/mp4"
        />
        <source
          src="https://static.solartrip.com/webm/index_page/loyalty/solar_cash.webm"
          type="video/webm"
        />
      </LoyaltyVideo>
      <LoyaltyVideoPoster
        className={styles.poster}
        src="https://static.solartrip.com/images/index_page/loyalty/solar_cash_poster.png"
      />
    </div>
  </LoyaltyMedia>
);
