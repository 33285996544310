import React from 'react';

import styles from './Ellipse.scss';
import cls from 'classnames';

type Props = {
  childrenClassNames?: {
    container?: string;
    ellipse?: string;
  };
};

export const Ellipse = ({ childrenClassNames = {} }: Props) => {
  return (
    <div className={childrenClassNames.container}>
      <div className={styles.ellipseAnimation}>
        <svg
          className={cls(styles.ellipse, childrenClassNames.ellipse)}
          width="500"
          viewBox="-2 -2 203 83"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="100"
            cy="40"
            rx="100"
            ry="40"
            strokeWidth="0.5"
            stroke="url(#gradient_1)"
            fill="transparent"
          />
          <defs>
            <linearGradient
              id="gradient_1"
              x1="0"
              y1="0"
              x2="200"
              y2="200"
              gradientUnits="userSpaceOnUse"
            >
              <animate
                attributeName="x1"
                values="0;200;200;0;0"
                dur="5s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="x2"
                values="200;0;0;200;200"
                dur="5s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y1"
                values="-50;-50;150;150;-50"
                dur="5s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y2"
                values="150;150;-50;-50;150"
                dur="5s"
                repeatCount="indefinite"
              />
              <stop stopColor="#EED7F1" />
              <stop offset="0.084745" stopColor="#FEFCFE" />
              <stop offset="0.191502" stopColor="#DBC1D4" />
              <stop offset="0.285358" stopColor="#6DD2E5" />
              <stop offset="0.400018" stopColor="#7BA0EB" />
              <stop offset="0.509667" stopColor="#BC86F6" />
              <stop offset="0.631842" stopColor="#DCC4F8" />
              <stop offset="0.747457" stopColor="#BFB5C6" />
              <stop offset="0.870443" stopColor="#F5F4F6" />
              <stop offset="1" stopColor="#D7CACE" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};
