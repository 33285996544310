import useQuery from "src/hooks/useQuery";


const useIsWebview = () => {
  const {view} = useQuery();

  return view === 'app';
};

export default useIsWebview;
