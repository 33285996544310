import { SurchargeState } from 'src/redux/modules/surcharge/types';

const pollingSelector = ({ surcharge }: { surcharge: SurchargeState }) => {
  const {
    payPolling: { data },
  } = surcharge;

  return data || {};
};

export default pollingSelector;
