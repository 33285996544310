export const FETCH_SURCHARGE_DATA = 'surcharge/FETCH_SURCHARGE_DATA';
export const FETCH_SURCHARGE_DATA_SUCCESS = 'surcharge/FETCH_SURCHARGE_DATA_SUCCESS';
export const FETCH_SURCHARGE_DATA_FAIL = 'surcharge/FETCH_SURCHARGE_DATA_FAIL';
export const FETCH_SURCHARGE_DATA_RESET = 'surcharge/FETCH_SURCHARGE_DATA_RESET';

export const PAY_REQUEST = 'surcharge/PAY_REQUEST';
export const PAY_REQUEST_SUCCESS = 'surcharge/PAY_REQUEST_SUCCESS';
export const PAY_REQUEST_FAIL = 'surcharge/PAY_REQUEST_FAIL';
export const PAY_REQUEST_RESET = 'surcharge/PAY_REQUEST_RESET';

export const PAY_POLLING = 'surcharge/PAY_POLLING';
export const PAY_POLLING_SUCCESS = 'surcharge/PAY_POLLING_SUCCESS';
export const PAY_POLLING_FAIL = 'surcharge/PAY_POLLING_FAIL';
export const PAY_POLLING_RESET = 'surcharge/PAY_POLLING_RESET';

export const SET_PAYMENT_GATES = 'surcharge/SET_PAYMENT_GATES';
export const SET_CURRENT_PAYMENT_GATE = 'surcharge/SET_CURRENT_PAYMENT_GATE';

export const SET_SURCHARGE_ID = 'surcharge/SET_SURCHARGE_ID';

export const SET_IS_PAYMENT_IN_PROGRESS = 'surcharge/SET_IS_PAYMENT_IN_PROGRESS';

export type loading = boolean;

export type SurchargeProcessState = {
  isPaymentInProgress: boolean;
};

export type SetIsPaymentInProgressAction = {
  type: typeof SET_IS_PAYMENT_IN_PROGRESS;
  isPaymentInProgress: boolean;
};

export type SurchargeProcessActionTypes = SetIsPaymentInProgressAction;

export type Gate = {
  method: string;
  tag: string;
  currency: string;
  total: number;
  value: string;
  title: string;
};

export type PaymentGate = {
  provider: string;
  currency: string;
  paymentMethod: string;
  tag: string;
  total: number;
  icons: string[];
};

export type PreparedGates = {
  method: string;
  tag: string;
  currency: string;
  total: number;
  value: string;
  title?: string;
  icons?: string[];
  paymentMethod?: string;
};

export type SurchargeCommonState = {
  surchargeId: string;
  currentGate: Gate | null;
  paymentGates: Gate[];
};

export type SetSurchargeIdAction = {
  type: typeof SET_SURCHARGE_ID;
  surchargeId: string;
};

export type SetPaymentGatesAction = {
  type: typeof SET_PAYMENT_GATES;
  paymentGates: Gate[];
};

export type SetCurrentPaymentGateAction = {
  type: typeof SET_CURRENT_PAYMENT_GATE;
  gate: Gate;
};

export type SurchargeCommonActionTypes =
  | SetSurchargeIdAction
  | SetPaymentGatesAction
  | SetCurrentPaymentGateAction;

export type SurchargeDataState = {
  loading: boolean;
  error?: string;
};

export type PayRequestState = {
  loading: boolean;
  error?: string;
};

export type PayPollingResult = {
  postParams?: {
    cashierKey: string;
    cashierToken: string;
  };
  isBridgerPay?: boolean;
  status?: string;
};

export type PayPollingState = {
  initial: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
  cancelled: boolean;
  data?: PayPollingResult;
};

export type CommonState = {
  auth: {
    infoStatus: {
      loading: boolean;
    };
  };
  cardSuggest?: any;
};

export type SurchargeState = {
  surchargeData: SurchargeDataState;
  payRequest: PayRequestState;
  payPolling: PayPollingState;
  surchargeCommon: SurchargeCommonState;
  surchargeProcess: SurchargeProcessState;
  common: SurchargeCommonState;
};
