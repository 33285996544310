import { SurchargeState } from 'src/redux/modules/surcharge/types';

const loadingSelector = ({ surcharge }: { surcharge: SurchargeState }) => {
  const {
    surchargeData: { loading: surchargeDataLoading },
    payRequest: { loading: payRequestLoading },
    payPolling: { loading: payPollingLoading },
  } = surcharge;

  return { payRequestLoading, surchargeDataLoading, payPollingLoading };
};

export default loadingSelector;
