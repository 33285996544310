import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useInView } from 'react-intersection-observer';

import cls from 'classnames';

import { l10n, l10nhtml } from '@ott/l10n';

import { AviaDeal } from 'src/components/blocks/Deals/blocks/AviaDeal';
import { HotelsDeal } from 'src/components/blocks/Deals/blocks/HotelsDeal';
import { SkeletonItem } from 'src/components/elements/SkeletonItem';

import useMarketingTexts from 'src/hooks/useMarketingTexts';

import styles from './Deals.scss';

type DealsProps = {
  activeProduct: 'avia' | 'hotels';
  departureCities?: Record<string, string>;
};

export const Deals = React.memo(({ activeProduct, departureCities = {} }: DealsProps) => {
  const [inViewRef, isInView] = useInView();

  const [isInitial, setIsInitial] = useState(true);
  const [selectedDeal, setSelectedDeal] = useState<string>(Object.keys(departureCities)[0] || '');

  const { result: deals, isLoading } = useMarketingTexts({
    product: activeProduct,
    shouldFetch: isInView || !isInitial,
  });

  useEffect(() => {
    setSelectedDeal(Object.keys(departureCities)[0] || '');
  }, [departureCities]);

  useEffect(() => {
    if (isInView) {
      setIsInitial(false);
    }
  }, [isInView]);

  return (
    <div className={styles.root} ref={inViewRef} data-locator='deals-block'>
      <div className={styles.dealsHeader}>
        <div className={styles.dealsTitle}>{l10nhtml('deals.title')}</div>
        {activeProduct === 'avia' && (
          <div className={styles.dealsDeparture}>
            {Object.keys(departureCities).map((key) => (
              <button
                type="button"
                onClick={() => setSelectedDeal(key)}
                className={cls(styles.dealButton, { [styles.isActive]: selectedDeal === key })}
                data-locator={`deal-from-${key}`}
              >
                {departureCities[key]}
              </button>
            ))}
            <AnchorLink href="#search-form-switcher" className={styles.dealButton} data-locator="return-to-search-button">
              {l10n('deals.myLocation')}
            </AnchorLink>
          </div>
        )}
      </div>
      <div className={styles.dealsGallery}>
        {isLoading && (
          <>
            <SkeletonItem className={styles.dealSkeleton} isDark={true} />
            <SkeletonItem className={styles.dealSkeleton} isDark={true} />
            <SkeletonItem className={styles.dealSkeleton} isDark={true} />
          </>
        )}
        {deals.map((deal, index) => {
          return activeProduct === 'avia' ? (
            <AviaDeal
              deal={deal}
              selectedDeal={selectedDeal}
              departureCities={departureCities}
              // умышленно каждый раз новые ключи
              // eslint-disable-next-line react/no-array-index-key
              key={selectedDeal + index}
            />
          ) : (
            // eslint-disable-next-line no-underscore-dangle
            <HotelsDeal deal={deal} key={deal._id} />
          );
        })}
      </div>
    </div>
  );
});
