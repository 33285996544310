import { createSlice } from '@reduxjs/toolkit';

import { Config } from './types';

const configSlice = createSlice({
  name: 'config',
  initialState: {} as Config,
  reducers: {
    update: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { update } = configSlice.actions;
export const reducer = configSlice.reducer;
