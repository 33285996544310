import React from 'react';
import { useSelector } from 'react-redux';

import { noop } from 'lodash';

import PaymentVariants from '@ott/payment-variants';

import commonSelector from '../../../../selectors/commonSelector';

import styles from './GateSwitcher.scss';

export const GateSwitcher = () => {
  const { paymentGates, currentGate } = useSelector(commonSelector);

  if (!currentGate) {
    return null;
  }

  const { value } = currentGate;

  return (
    <PaymentVariants
      allowSingleVaraint={true}
      onChange={noop}
      variants={paymentGates}
      value={value}
      className={styles.paymentVariants}
    />
  );
};
