import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import cls from 'classnames';

import { useLayoutContext } from '@ott/layout-context';

import usePreviousValue from 'src/hooks/usePreviousValue';

import styles from './Card.scss';

export type CardProps = {
  title: string;
  description: string;
  backgroundURL?: string;
  backgroundURLMobile?: string;
  videoBackgroundURL?: string;
  videoBackgroundURLMobile?: string;
  isWide?: boolean;
};

export const Card = ({
  title,
  description,
  backgroundURL,
  backgroundURLMobile,
  videoBackgroundURL,
  videoBackgroundURLMobile,
  isWide,
}: CardProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isInitialInView, setIsInitialInView] = useState(true);

  const [inViewRef, isInView] = useInView();
  const videoRef = useRef<HTMLVideoElement>(null);

  const prevIsInView = usePreviousValue(isInView);
  const { isMobile } = useLayoutContext();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (videoRef.current && isInView) {
      videoRef.current.load();
      videoRef.current.play();
    }
  }, [isMobile]);

  useEffect(() => {
    if (!prevIsInView && isInView && videoRef.current) {
      videoRef.current.play();
    }
  }, [isInView, prevIsInView]);

  useEffect(() => {
    if (isInView) {
      setIsInitialInView(false);
    }
  }, [isInView]);

  const getVideoPreloadAttribute = () => {
    if (!isMounted) {
      return 'none';
    }

    if (isInView || !isInitialInView) {
      return 'auto';
    }

    return 'none';
  };

  return (
    <div className={cls(styles.root, { [styles.rootWide]: isWide })} data-locator="onboard-card">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      {backgroundURL && (
        <div
          className={styles.backgroundImage}
          style={{
            backgroundImage: `url(${backgroundURL})`,
          }}
        />
      )}
      {backgroundURLMobile && (
        <div
          className={cls(styles.backgroundImage, styles.backgroundImageMobile)}
          style={{
            backgroundImage: `url(${backgroundURLMobile})`,
          }}
        />
      )}
      {videoBackgroundURL && (
        <video
          ref={(node) => {
            if (node) {
              (videoRef as MutableRefObject<HTMLVideoElement>).current = node;
              inViewRef(node);
            }
          }}
          playsInline={true}
          preload={getVideoPreloadAttribute()}
          muted={true}
          className={styles.backgroundVideo}
        >
          <source
            src={
              isMobile && videoBackgroundURLMobile ? videoBackgroundURLMobile : videoBackgroundURL
            }
            type="video/mp4"
          />
        </video>
      )}
      <h2
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
        data-locator="onboard-card-title"
      />
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
        data-locator="onboard-card-description"
      />
    </div>
  );
};
