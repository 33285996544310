import { Deals } from 'src/components/blocks/Deals';
import { LoyaltySection } from 'src/components/blocks/LoyaltySection';
import { MobileApp } from 'src/components/blocks/MobileApp';
import { NotFoundPage } from 'src/components/blocks/NotFoundPage';
import { OnboardCards } from 'src/components/blocks/OnboardCards';
import { SearchFormSwitcher } from 'src/components/blocks/SearchFormSwitcher';

// Все компоненты должны быть строго на тс и принимать один или ноль аргументов - пропсы
// иметь именованный тип пропсов, либо не иметь вовсе, тобишь не Props а NameProps
const map = {
  notFound: NotFoundPage,
  formSwitcher: SearchFormSwitcher,
  onboardCards: OnboardCards,
  loyaltyProgram: LoyaltySection,
  mobileApp: MobileApp,
  deals: Deals,
} as const;

export default map;
