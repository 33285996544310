import superagent from '@ott/superagent';

const url = '/_hotels_order/public/extraCharge/status';

const getPayStatus = (surchargeId: string): Promise<any> => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => superagent.get(`${url}/${surchargeId}`).then(resolve));
};

export default getPayStatus;
