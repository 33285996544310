import React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';

import { Store } from 'src/redux/store';

import { App } from './components/blocks/App';

const Root = ({ store }: { store: Store }) => {
  if (!store) {
    return null;
  }

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default hot(module)(Root);
