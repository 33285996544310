import { types as HotelsSearchFormTypes } from '@ott/hotels-search-form';
import superagent from '@ott/superagent';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const url = __WHITE_LABEL__? '/_hotels/api/getHotelsConfig' : '/_hotels/solartrip/api/getHotelsConfig';

type Params = {
  lang: string;
  locale: string;
};

export const getHotelsConfig = createAsyncThunk<
  HotelsSearchFormTypes.HotelsConfig,
  Params,
  { rejectValue: unknown }
>('hotelsConfig/get', async (requestParams, { rejectWithValue }) => {
  const res = await superagent.get(url).query(requestParams).set('Accept', 'application/json');

  if (res && res.body && res.body.result) {
    return res.body.result;
  }

  return rejectWithValue(res.body.error);
});

const configSlice = createSlice({
  name: 'config',
  initialState: {} as HotelsSearchFormTypes.HotelsConfig,
  reducers: {},
  extraReducers: {
    [getHotelsConfig.fulfilled.type]: (_, action) => action.payload,
  },
});

export default configSlice.reducer;
