import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import SearchForm, { types as HotelsSearchFormTypes } from '@ott/hotels-search-form';
import { lang } from '@ott/l10n';

import { getHotelsConfig } from 'src/redux/modules/hotelsConfig/reducer';
import { selectHotelsConfig } from 'src/redux/selectors/hotelsConfig';

import { metrics } from 'src/utility/metrics';

import { HOTELS_HAPI } from 'src/constants/common';

import useAuth from 'src/hooks/useAuth';
import usePreviousValue from 'src/hooks/usePreviousValue';
import useQuery from 'src/hooks/useQuery';
import useTypedSelector from 'src/hooks/useTypedSelector';

const [language, locale] = (lang || '').split('-');

export const HotelsSearchForm = React.memo(() => {
  const query = useQuery();
  const dispatch = useDispatch();

  const { isAuthorized } = useAuth();
  const prevIsAuthorized = usePreviousValue(isAuthorized);

  const hotelsConfig = useTypedSelector(selectHotelsConfig);

  const submit = useCallback(({ url, params }: HotelsSearchFormTypes.SubmitPayload) => {
    metrics('start_solar_search');
    metrics('start_solar_hotels_search', params);

    const preparedQueryString = Object.keys(params)
      .map((key) => {
        if (!params[key]) {
          return null;
        }

        if (key === 'rooms') {
          return params[key].reduce((acc: string, room: string) => {
            if (!acc) {
              return `rooms[]=${room}`;
            }

            return `${acc}&rooms[]=${room}`;
          }, '');
        }

        return `${key}=${params[key]}`;
      })
      .filter(Boolean)
      .join('&');

    window.location.href = `${window.location.origin}/${lang}/hotels${url}?${preparedQueryString}`;
  }, []);

  const fetchHotelsConfig = () => {
    dispatch(
      getHotelsConfig({
        lang: language || 'en-us',
        locale: locale || language || 'en-us',
      })
    );
  };

  useEffect(() => {
    /**
     * TODO: Придумать как решить проблему без перезагрузки страницы
     *
     * В сафари наблюдалась проблема при возврате через кнопку back браузера
     * Оказалось, все дело в т.н. BF кэше. Здесь об этом подробнее
     * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
     */

    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  useEffect(() => {
    fetchHotelsConfig();
  }, []);

  useEffect(() => {
    if (prevIsAuthorized !== isAuthorized) {
      // При авторизации или выходе получаем повторно hotelsConfig
      fetchHotelsConfig();
    }
  });

  return (
    <SearchForm
      context="ott"
      submitGtmLocator="hotel-search-mainform"
      hapi={HOTELS_HAPI}
      hotelsConfig={hotelsConfig}
      onSubmit={submit}
      query={query}
      backgroundTheme="dark"
    />
  );
});
