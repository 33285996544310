import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Container, Row } from '@ott/grid';
import { l10n, l10nhtml } from '@ott/l10n';
import Modal, { modalIcons } from '@ott/modal';
import { authActions } from '@ott/solar-auth';
import Header from '@ott/solar-header';

import { setSurchargeId, resetPayState } from 'src/redux/modules/surcharge/actions';
import { fetchSurchargeData } from 'src/redux/modules/surcharge/actions/hotels';

import { LoadingOverlay } from './blocks/LoadingOverlay';
import { PayForm } from './blocks/PayForm';
import { PaymentTimer } from './blocks/PaymentTimer';
import Skeleton from './blocks/Skeleton/Skeleton';
import { SURCHARGE_STATUSES, ERRORS, KNOWN_PRODUCTS, SKELETON_TIME } from './constants';
import errorSelector from './selectors/errorSelector';
import loadingSelector from './selectors/loadingSelector';
import pollingSelector from './selectors/pollingSelector';

import { History } from 'history';

import styles from './SurchargePage.scss';

type SurchargePageProps = {
  history: History;
  product: string;
  surchargeId: string;
};

export const SurchargePage: React.FC<SurchargePageProps> = ({ history, product, surchargeId }) => {
  const dispatch = useDispatch();

  const [isSkeletonShown, setIsSkeletonShown] = useState(true);

  const isPaymentInProgress = useSelector(({ surcharge }) => surcharge.process.isPaymentInProgress);
  const surchargeData = useSelector(({ surcharge }) => surcharge.surchargeData.data);
  const { surchargeDataError, payRequestError, payPollingError } = useSelector(errorSelector);
  const { surchargeDataLoading } = useSelector(loadingSelector);
  const payPolling = useSelector(pollingSelector);
  const hasError = payRequestError || payPollingError || !!surchargeDataError;

  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);

  const closeErrorModal = useCallback(() => {
    setErrorModalIsOpen(false);
    dispatch(resetPayState());
    window.location = window.location.pathname;
  }, [dispatch]);

  const closeSuccessModal = () => {
    window.location.href = '/';
  };

  const onOrderDie = () => {
    setErrorModalIsOpen(true);
  };

  useLayoutEffect(() => {
    /**
     * Делаем небольшую задержку, чтобы избежать мелькания скелетона
     */

    setTimeout(() => setIsSkeletonShown(false), SKELETON_TIME);

    if (!surchargeId || !KNOWN_PRODUCTS.includes(product)) {
      return;
    }
    dispatch(authActions.getBasicUserInfo());
    dispatch(setSurchargeId(surchargeId));

    dispatch(fetchSurchargeData(surchargeId));
  }, []);

  useEffect(() => {
    if (hasError) {
      setErrorModalIsOpen(true);
    }
  }, [hasError]);

  const errorModalNode = (
    <Modal isOpen={errorModalIsOpen} onClose={closeErrorModal} bottomSheetOnMobile={true}>
      {{
        icon: <modalIcons.ModalErrorIcon />,
        title: l10n('surcharge.errorTitle'),
        body: l10nhtml('surcharge.errorBody'),
        primaryButton: {
          text: l10n('surcharge.errorButton'),
          onClick: closeErrorModal,
        },
      }}
    </Modal>
  );

  const renderContent = () => {
    if (isSkeletonShown || surchargeDataLoading) {
      /**
       * На сервере страница должна сгенерироваться по этой ветке,
       * чтобы избежать мелькания error-state
       */
      return <Skeleton />;
    }

    const isPayPollingSuccess = payPolling.status === SURCHARGE_STATUSES.SUCCESS;

    if (surchargeDataError === ERRORS.PAID_EARLY || isPayPollingSuccess) {
      return (
        <Modal isOpen={true} onClose={closeSuccessModal} bottomSheetOnMobile={true}>
          {{
            icon: <modalIcons.ModalCheckIcon />,
            title: l10n('surcharge.paymentSuccessTitle'),
            body: l10nhtml('surcharge.paymentSuccessBody'),
            primaryButton: {
              text: l10n('surcharge.paymentSuccessButton'),
              onClick: closeSuccessModal,
            },
          }}
        </Modal>
      );
    }

    return <PayForm product={product} surchargeId={surchargeId} />;
  };

  const getLeftTime = () => {
    const { created, expirationHours } = surchargeData;

    const date = new Date(created);
    date.setHours(date.getHours() + expirationHours);

    return date;
  };

  const leftTime = surchargeData ? getLeftTime() : null;

  return (
    <>
      <Header shouldShowOnlyLogo={true} activeProduct={product} basename="/" history={history} />
      {!isSkeletonShown && <PaymentTimer date={leftTime} onOrderDie={onOrderDie} />}
      <div className={styles.content}>
        <Container>
          <Row>
            <Col desktop={{ offset: 4, span: 4 }} mobile={4} className={styles.col}>
              <div className={styles.payFormContainer}>{renderContent()}</div>
            </Col>
          </Row>
        </Container>
        {isPaymentInProgress && <LoadingOverlay />}
        {errorModalNode}
      </div>
    </>
  );
};
