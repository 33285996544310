import React from 'react';

import { LoyaltyMedia as BaseLoyaltyMedia, LoyaltyVideo, LoyaltyVideoPoster } from '../../Loyalty';

import styles from './LoyaltyMedia.scss';

export const LoyaltyMedia = () => (
  <BaseLoyaltyMedia>
    <div className={styles.container}>
      <LoyaltyVideo className={styles.video}>
        <source
          src="https://static.solartrip.com/mp4/index_page/loyalty/loyalty_rewards.mp4"
          type="video/mp4"
        />
        <source
          src="https://static.solartrip.com/webm/index_page/loyalty/loyalty_rewards.webm"
          type="video/webm"
        />
      </LoyaltyVideo>
      <LoyaltyVideoPoster
        className={styles.poster}
        src="https://static.solartrip.com/images/index_page/loyalty/loyalty_rewards_poster.png"
      />
    </div>
  </BaseLoyaltyMedia>
);
