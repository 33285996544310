import { combineReducers } from 'redux';
import { uploadAvatar, deleteAvatar, getUserTripsStats } from '@ott/shared-solar-logic';
import authModalReducer from '@ott/solar-auth';
import { limiter } from '@ott/superagent';
import { unconfirmedOrders } from '@ott/reservation-banner';

export default combineReducers({
  uploadAvatar,
  deleteAvatar,
  tripsStats: getUserTripsStats,
  limiter: limiter.reducer,
  auth: authModalReducer,
  unconfirmedOrders,
});
