import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { suggests, heatmap } from '@ott/flights-search-form';
import { reducer as HSFReducer } from '@ott/hotels-search-form';

import common from './modules/common/reducer';
import hotelsConfig from './modules/hotelsConfig/reducer';
import index from './modules/index/reducer';
import { surcharge } from './modules/surcharge/reducer';

export default function createReducer() {
  return combineReducers({
    form: formReducer,
    index,
    common,
    avia: combineReducers({
      searchForm: combineReducers({
        suggests,
        heatmap,
      }),
    }),
    surcharge,
    hotels: combineReducers({
      searchForm: HSFReducer,
      hotelsConfig,
    }),
  });
}
