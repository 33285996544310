import React from 'react';

import { Card } from 'src/components/blocks/OnboardCards/blocks/Card';
import { CardProps } from 'src/components/blocks/OnboardCards/blocks/Card/Card';

import styles from './OnboardCards.scss';

type OnboardCardsProps = {
  cards: CardProps[];
  title: string;
};

export const OnboardCards = React.memo(({ cards, title }: OnboardCardsProps) => {
  return (
    <div className={styles.root} id="onboard">
      <h1
        className={styles.caption}
        dangerouslySetInnerHTML={{ __html: title }}
        data-locator="onboard-title"
      />
      <div className={styles.cardsContainer}>
        {cards.map((card, i) => (
          // eslint-disable-next-line react/no-array-index-key,react/jsx-props-no-spreading
          <Card key={i} {...card} />
        ))}
      </div>
    </div>
  );
});
