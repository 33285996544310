import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router';

import cls from 'classnames';

import ga, { initGoogleAnalytics, SOLARTRIP_COUNTER } from '@ott/google-analytics';
import createGoogleTagManager from '@ott/google-tag-manager';
import { Col, Container, Row } from '@ott/grid';
import { lang } from '@ott/l10n';
import { useLayoutContext } from '@ott/layout-context/src';
import Footer from '@ott/solar-footer';
import Header from '@ott/solar-header';
import { envEasterEgg } from '@ott/utility-env-easter-egg';
import SupportChat from '@ott/support-chat';

import Loadable from '@react-loadable/revised/index';

import { Config } from 'src/redux/modules/index/config/types';

import Empty from 'src/components/base/Empty';
import componentsMap from 'src/components/componentsMap';

import { metrics } from 'src/utility/metrics';

import useAuth from 'src/hooks/useAuth';
import useIsWebview from 'src/hooks/useIsWebview';
import useQuery from 'src/hooks/useQuery';

import * as localStorage from 'local-storage';

import styles from './IndexPage.scss';

const SHOW_NOT_PRIORITY_BLOCKS_TIMEOUT = 4000;

const AsyncReservationBanner = Loadable({
  loader: async () => {
    const Component = await import(
      '@ott/reservation-banner' /* webpackChunkName: "ReservationBanner" */
    );

    return Component.ReservationBanner;
  },
  loading: Empty,
});

const AsyncCookiePolicy = Loadable({
  loader: async () => {
    const Component = await import('@ott/cookie-policy' /* webpackChunkName: "CookiePolicy" */);

    return Component.CookiePolicy;
  },
  loading: Empty,
});

function checkNode(
  node: HTMLElement,
  nodeCheckDelegate: (node: HTMLElement) => boolean,
  checkCount = 3
): boolean {
  if (!checkCount || !node) {
    return false;
  }

  if (nodeCheckDelegate(node)) {
    return true;
  }

  if (!node.parentNode) {
    return false;
  }

  return checkNode(node.parentNode as HTMLElement, nodeCheckDelegate, checkCount - 1);
}

export const IndexPage = ({
  config,
  product,
}: {
  config: Config[keyof Config];
  product: 'avia' | 'hotels' | '404';
}) => {
  const [cookieTimeoutExpired, setCookieTimeoutExpired] = useState(false);
  const [shouldShowCookiePolicy, setShouldShowCookiePolicy] = useState(false);
  const [isCookiePolicyAccepted, setIsCookiePolicyAccepted] = useState(
    localStorage.get('cookiePolicyIsAccepted') === true
  );
  const [newMessages, setNewMessages] = useState(0);
  const [isSupportChatVisible, setIsSupportChatVisible] = useState(false);
  const [isSupportChatOpen, setIsSupportChatOpen] = useState(false);

  const [isNotScrolledRef, isNotScrolled] = useInView({ threshold: 0 });

  const { isAuthorized } = useAuth();
  const { isMobile } = useLayoutContext();
  const history = useHistory();
  const query = useQuery();
  const isWebview = useIsWebview();

  const startQueryParamLogic = () => {
    const { testPg } = query || {};

    if (testPg) {
      window.localStorage.setItem(`OTT/${product}/testPg`, String(!!testPg));
    }
  };

  const initGa = () => {
    initGoogleAnalytics({
      counterId: SOLARTRIP_COUNTER,
    })
      .then(() => {
        ga('send', 'pageview');
      })
      .catch((err: unknown) => {
        console.error(err);
      });
  };

  const handleNewMessages = useCallback((count: number) => {
    setNewMessages(count);
  }, []);

  const handleOpenSupportChat = useCallback((isOpen: boolean) => {
    setIsSupportChatOpen(isOpen);
  }, []);

  const onSupportChatVisible = useCallback((isVisible: boolean) => {
    setIsSupportChatVisible(isVisible);
  }, []);

  const renderItems = () => {
    if (!config || !config.items) {
      return null;
    }

    return config.items.map((item) => {
      const Comp: React.FunctionComponent<any> = componentsMap[item.component];

      if (!Comp) {
        console.error(`There's no key ${item.component} in componentsMap!`);

        return null;
      }

      return (
        <Comp
          key={item.key}
          id={item.key}
          className={cls({
            [styles.hideOnMobile]: item.hideOnMobile,
          })}
          config={config}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...item.props}
        />
      );
    });
  };

  const renderCookiePolicy = () => {
    if (isWebview || !shouldShowCookiePolicy) {
      return null;
    }

    return (
      <AsyncCookiePolicy
        // @ts-ignore
        onAccept={() => setIsCookiePolicyAccepted(true)}
        cookieLink={`https://support.solartrip.com/hc/${lang}/articles/4789512322717-Cookies-Policy`}
      />
    );
  };

  useEffect(() => {
    metrics('solar_index_show_page');

    envEasterEgg();

    setTimeout(() => {
      setCookieTimeoutExpired(true);
    }, SHOW_NOT_PRIORITY_BLOCKS_TIMEOUT);

    startQueryParamLogic();
  }, []);

  useEffect(() => {
    if (isCookiePolicyAccepted) {
      createGoogleTagManager('GTM-K5DP3HL');
      initGa();
    }
  }, [isCookiePolicyAccepted]);

  // Такая хренотень нужна чтобы плашка с куками не вылазила на pageinsights, потому что она сильно ухудшает LCP
  useEffect(() => {
    const showCookiePolicy = () => {
      if (cookieTimeoutExpired) {
        setShouldShowCookiePolicy(true);
      }
    };

    const handleDocumentClick = ({ target }: Pick<MouseEvent, 'target'>) => {
      const interactiveElementsTagNames = ['button', 'input', 'textarea', 'a'];

      const targetIsInteractiveElement = checkNode(target as HTMLElement, (node) => {
        // Double check if node is not disposed or undefined
        if (!node || !node.tagName) {
          return false;
        }

        const nodeTagName = node.tagName.toLowerCase();

        return interactiveElementsTagNames.includes(nodeTagName);
      });

      if (targetIsInteractiveElement) {
        showCookiePolicy();
      }
    };

    window.addEventListener('click', handleDocumentClick);
    window.addEventListener('scroll', showCookiePolicy);

    return () => {
      window.removeEventListener('click', handleDocumentClick);
      window.removeEventListener('scroll', showCookiePolicy);
    };
  }, [cookieTimeoutExpired]);

  const reservationBannerNode = (
    // @ts-ignore
    <AsyncReservationBanner isMobile={isMobile} isAuth={isAuthorized} product="index" />
  );

  return (
    <div className={styles.content}>
      {config.meta && <Helmet title={config.meta.title} />}
      {reservationBannerNode}
      {!isWebview && (
        <div className={styles.headerContainer}>
          <div className={styles.isPageScrolled} ref={isNotScrolledRef} />
          <Header
            className={styles.header}
            history={history}
            theme="dark"
            showNavigation={product === '404'}
            isTransparent={product === '404'}
            isFixed={!isNotScrolled}
            supportNewMessagesCount={newMessages}
            onOpenSupportChat={handleOpenSupportChat}
            isSupportChatVisible={isSupportChatVisible}
          />
        </div>
      )}
      {renderCookiePolicy()}
      <Container>
        <Row>
          <Col desktop={12} mobile={4}>
            {renderItems()}
          </Col>
        </Row>
      </Container>
      <SupportChat
        isUserAuthorized={isAuthorized}
        isSolar={true}
        isChatButtonHidden={false}
        isOpenFromOutside={isSupportChatOpen}
        onOpenFromOutside={handleOpenSupportChat}
        onReceiveNewMessages={handleNewMessages}
        onCheckAvailability={onSupportChatVisible}
      />
      {!isWebview && <Footer />}
    </div>
  );
};
