import { PRODUCTS } from 'src/components/pages/SurchargePage/constants';

import { pay as hotelsPay } from '../actions/hotels';

type ProductType = typeof PRODUCTS.HOTELS;

const getPayActionCreator = (product: ProductType) => {
  switch (product) {
    case PRODUCTS.HOTELS:
      return hotelsPay;

    default:
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
  }
};

export default getPayActionCreator;
