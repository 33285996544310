import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, matchPath, Route, Switch } from 'react-router-dom';

import ErrorBoundary from '@ott/error-boundary';
import { LayoutContextProvider } from '@ott/layout-context';
import { MessageContextProvider } from '@ott/message';
import { authActions } from '@ott/solar-auth';
import { limiter } from '@ott/superagent';

import { selectIndexConfig } from 'src/redux/selectors/common';

import { IndexPage } from 'src/components/pages/IndexPage';
import { SurchargePage } from 'src/components/pages/SurchargePage';

import useAuth from 'src/hooks/useAuth';
import useTypedSelector from 'src/hooks/useTypedSelector';

import { History } from 'history';

const DEFAULT_PRODUCT = 'hotels';
const DEFAULT_LANG = 'en-us';

const availableLanguages = ['en-us', 'en-ie', 'ru', 'en-ae'] as const;
const availableProducts = ['avia', 'hotels'] as const;

export const App = () => {
  const config = useTypedSelector(selectIndexConfig);
  const dispatch = useDispatch();
  const auth = useAuth();

  const renderErrorRoute = ({ history }: { history: History }) => {
    const langMatch = matchPath(history.location.pathname, {
      path: '/:lang/',
    });

    const lang = langMatch ? (langMatch.params as any).lang : DEFAULT_LANG;

    if (availableLanguages.includes(lang)) {
      return <IndexPage config={config[`${lang}/404`]} product="404" />;
    }

    return <IndexPage config={config[`${DEFAULT_LANG}/404`]} product="404" />;
  };

  const renderSurchargePage = ({ history }: { history: History }) => {
    const langMatch = matchPath(history.location.pathname, {
      path: '/:lang/',
    });

    const { lang } = langMatch ? (langMatch.params as any) : { lang: DEFAULT_LANG };

    if (!availableLanguages.includes(lang)) {
      return renderErrorRoute({ history });
    }

    const fullUrlMatch = matchPath(history.location.pathname, {
      path: '/:lang/surcharge/hotels/:surchargeId',
    });

    const { surchargeId } = fullUrlMatch ? (fullUrlMatch.params as any) : null;

    if (!surchargeId) {
      return renderErrorRoute({ history });
    }

    return <SurchargePage history={history} product="hotels" surchargeId={surchargeId} />;
  };

  const renderIndexRoute = ({ history }: { history: History }) => {
    const langMatch = matchPath(history.location.pathname, {
      path: '/:lang/',
    });

    const { lang }: { lang: typeof availableLanguages[number]} = langMatch ? (langMatch.params as { lang: typeof availableLanguages[number]}) : { lang: DEFAULT_LANG };

    if (availableLanguages.includes(lang)) {
      const fullUrlMatch = matchPath(history.location.pathname, {
        path: '/:lang/:product',
      });

      const { product = DEFAULT_PRODUCT } = fullUrlMatch
        ? (fullUrlMatch.params as { product: typeof availableProducts[number]})
        : { product: DEFAULT_PRODUCT };

      if (!langMatch && config[`${DEFAULT_LANG}/${product}`].statusCode === 404) {
        return renderErrorRoute({ history });
      }

      const currentConfig = config[`${lang}/${product}`];

      if (currentConfig.statusCode === 404) {
        return renderErrorRoute({ history });
      }

      return <IndexPage config={currentConfig} product={product || DEFAULT_PRODUCT} />;
    }

    return renderErrorRoute({ history });
  };

  const renderRouteNode = () => {
    return (
      <Switch>
        <Route exact={true} path="/" render={renderIndexRoute} />
        <Route exact={true} path="/:lang/" render={renderIndexRoute} />
        <Route
          exact={true}
          path="/:lang/surcharge/hotels/:surchargeId"
          render={renderSurchargePage}
        />
        <Route exact={true} path="/:lang/:product(avia|hotels)" render={renderIndexRoute} />
        <Route render={renderErrorRoute} />
      </Switch>
    );
  };

  const renderRouterNode = () => {
    const routeNode = renderRouteNode();

    if (__SERVER__) {
      return routeNode;
    }

    return <BrowserRouter>{routeNode}</BrowserRouter>;
  };

  useEffect(() => {
    if (auth.status.initial) {
      dispatch(authActions.getBasicUserInfo());
      dispatch(authActions.getUserInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <LayoutContextProvider>
        <MessageContextProvider>
          {renderRouterNode()}
          <limiter.Captcha />
        </MessageContextProvider>
      </LayoutContextProvider>
    </ErrorBoundary>
  );
};
