import { SurchargeState, CommonState } from 'src/redux/modules/surcharge/types';

const paySelector = (state: { surcharge: SurchargeState; common: CommonState }) => {
  return {
    isLoadingUserInfo: state.common.auth?.infoStatus?.loading,
    paymentCardInfo: state.common.cardSuggest,
    paymentGates: state.surcharge.common.paymentGates,
    currentGate: state.surcharge.common.currentGate,
  };
};

export default paySelector;
