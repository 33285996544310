import { combineReducers } from 'redux';

import createDataReducer from '@ott/create-data-reducer';

import { surchargeCommonReducer as common } from './config/common';
import { surchargeProcessReducer as process } from './config/process';
import * as T from './types';

export const surcharge = combineReducers({
  surchargeData: createDataReducer([
    T.FETCH_SURCHARGE_DATA,
    T.FETCH_SURCHARGE_DATA_SUCCESS,
    T.FETCH_SURCHARGE_DATA_FAIL,
    T.FETCH_SURCHARGE_DATA_RESET,
  ]),

  payRequest: createDataReducer([
    T.PAY_REQUEST,
    T.PAY_REQUEST_SUCCESS,
    T.PAY_REQUEST_FAIL,
    T.PAY_REQUEST_RESET,
  ]),

  payPolling: createDataReducer([
    T.PAY_POLLING,
    T.PAY_POLLING_SUCCESS,
    T.PAY_POLLING_FAIL,
    T.PAY_POLLING_RESET,
  ]),

  common,
  process,
});
