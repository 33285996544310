import {
  PAYMENT_METHODS,
  PaymentMethod,
  Product,
} from 'src/components/pages/SurchargePage/constants';

import { getRedirectUrl, getReturnUrl } from '.';

type SurchargeType = {
  common: {
    currentGate?: {
      tag: string;
      method: PaymentMethod;
    } | null;
    surchargeId: string;
  };
};

const getPaymentData = async ({ surcharge }: { surcharge: SurchargeType }, product: Product) => {
  const {
    common: { currentGate, surchargeId },
  } = surcharge;

  if (!currentGate) {
    return;
  }

  const { tag, method } = currentGate;

  const paymentData: { paySystem: string; continueUrl?: string } = {
    paySystem: tag,
  };

  const processUrls = {
    returnUrl: getReturnUrl(surchargeId, product),
    redirectUrl: getRedirectUrl(),
  };

  switch (method) {
    case PAYMENT_METHODS.WIDGET: {
      paymentData.continueUrl = processUrls.returnUrl;
      break;
    }

    default:
      // eslint-disable-next-line consistent-return
      return;
  }

  // eslint-disable-next-line consistent-return
  return paymentData;
};

export default getPaymentData;
