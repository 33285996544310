import React from 'react';

import { Deal } from 'src/hooks/useMarketingTexts';

import styles from '../../Deals.scss';

type Props = {
  deal: Deal;
  selectedDeal: string;
  departureCities: Record<string, string>
};

export const AviaDeal = React.memo(({ deal, selectedDeal, departureCities }: Props) => {
  const destinationKey =
    Object.keys(deal.custom).find((key) => {
      const data = deal.custom[key];

      if (data.from === departureCities[selectedDeal]) {
        return true;
      }

      return false;
    }) || 'destination0';

  const url = deal.custom[destinationKey]?.url || '';

  // next week
  const dealDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
  const dealDay = String(dealDate.getDate()).padStart(2, '0');
  const dealMonth = String(dealDate.getMonth() + 1).padStart(2, '0');

  if (deal.custom[destinationKey]?.from === departureCities[selectedDeal]) {
    return (
      <a
        key={deal.title}
        className={styles.deal}
        href={url.replace(/{{currentDate}}/g, `${dealDay}${dealMonth}`)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.dealName}>{deal.title}</div>
        <img className={styles.dealImage} src={deal.image} alt={deal.title} />
      </a>
    );
  }

  return null;
});
