import React from 'react';

import Button from '@ott/button';
import { l10n } from '@ott/l10n';

import { Ellipse } from 'src/components/elements/Ellipse';

import styles from './NotFoundPage.scss';

export const NotFoundPage = React.memo(() => {
  return (
    <div className={styles.NotFoundPage}>
      <div className={styles.content}>
        <div className={styles.code}>404</div>
        {l10n('notFound.title')}
        <Button className={styles.homeButton} href="/">
          {l10n('notFound.button')}
        </Button>
      </div>
      <Ellipse
        childrenClassNames={{ container: styles.ellipseContainer, ellipse: styles.ellipse }}
      />
    </div>
  );
});
