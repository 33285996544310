import { PRODUCTS } from 'src/components/pages/SurchargePage/constants';

import { startPayment as hotelsStartPayment } from '../actions/hotels';

type ProductType = typeof PRODUCTS.HOTELS;

const getStartPaymentActionCreator = (product: ProductType) => {
  switch (product) {
    case PRODUCTS.HOTELS:
      return hotelsStartPayment;

    default:
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
  }
};

export default getStartPaymentActionCreator;
