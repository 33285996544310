import Cookie from 'js-cookie';
import { TrackJS } from 'trackjs';
import { trackError } from 'utility/trackError';

if (!__SERVER__ && __DEV__) {
  new PerformanceObserver((entryList) => {
    for (const entry of entryList.getEntries()) {
      console.log('LCP candidate:', entry.startTime, entry);
    }
  }).observe({ type: 'largest-contentful-paint', buffered: true });
}

function initTrackJs() {
  const envId = document.documentElement.getAttribute('data-envid');
  const isTrackJsInitializationNeeded = envId && 'production'.includes(envId);

  if (!isTrackJsInitializationNeeded) {
    return;
  }

  // eslint-disable-next-line no-underscore-dangle
  TrackJS.install({
    token: '9434784669a9411185e8a512654b173a',
    application: 'solartrip',
    userId: Cookie.get('vid') || '',
  });

  TrackJS.addMetadata('repo', 'front-solar-index');
  TrackJS.addMetadata('environment', envId);

  global.trackJs = TrackJS;
}

if (__PROD__) {
  initTrackJs();
}

(async () => {
  try {
    import(/* webpackMode: "eager" */ './client');
  } catch (e) {
    trackError(e);
  }
})();
