import { THREE_DS_RETURN_QUERY_PARAM } from 'src/components/pages/SurchargePage/constants';

const getRedirectUrl = (): string => {
  const {
    location: { origin, pathname },
  } = window;

  return `${origin}${pathname}?${THREE_DS_RETURN_QUERY_PARAM}=true`;
};

export default getRedirectUrl;
