import * as T from '../types';

const initialState: T.SurchargeProcessState = {
  isPaymentInProgress: false,
};

export const surchargeProcessReducer = (
  state = initialState,
  action: T.SurchargeProcessActionTypes
): T.SurchargeProcessState => {
  switch (action.type) {
    case T.SET_IS_PAYMENT_IN_PROGRESS: {
      return {
        ...state,
        isPaymentInProgress: action.isPaymentInProgress,
      };
    }

    default:
      return state;
  }
};
