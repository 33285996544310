import React from 'react';

type Props = {
  className?: string;
};

export const AppStoreMobileApp = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="52"
      viewBox="0 0 180 52"
      fill="none"
      className={className}
    >
      <g opacity="0.2">
        <path
          d="M180 46.7875C180 49.6673 177.683 52 174.817 52H5.1901C2.32534 52 0 49.6673 0 46.7875V5.21933C0 2.34087 2.32534 0 5.1901 0H174.815C177.683 0 179.999 2.34087 179.999 5.21933L180 46.7875Z"
          fill="#F7F7F7"
        />
        <path
          d="M180 46.7875C180 49.6673 177.683 52 174.817 52H5.1901C2.32534 52 0 49.6673 0 46.7875V5.21933C0 2.34087 2.32534 0 5.1901 0H174.815C177.683 0 179.999 2.34087 179.999 5.21933L180 46.7875Z"
          fill="url(#paint0_linear_7833_128357)"
        />
      </g>
      <path
        d="M40.1695 25.7191C40.1308 21.5292 43.6881 19.4908 43.8508 19.3959C41.8361 16.532 38.7135 16.1407 37.6161 16.1095C34.9935 15.8404 32.4495 17.6396 31.1135 17.6396C29.7508 17.6396 27.6935 16.1355 25.4761 16.1797C22.6228 16.2226 19.9535 17.8333 18.4895 20.3345C15.4681 25.4344 17.7215 32.9289 20.6161 37.0512C22.0641 39.0701 23.7561 41.3243 25.9708 41.245C28.1375 41.1579 28.9468 39.8982 31.5615 39.8982C34.1521 39.8982 34.9121 41.245 37.1708 41.1943C39.4961 41.1579 40.9601 39.1663 42.3575 37.1292C44.0308 34.8152 44.7028 32.5363 44.7295 32.4193C44.6748 32.4011 40.2135 30.741 40.1695 25.7191Z"
        fill="white"
      />
      <path
        d="M35.9028 13.3977C37.0681 11.9768 37.8655 10.0437 37.6441 8.08203C35.9575 8.15483 33.8481 9.21953 32.6335 10.6092C31.5588 11.8338 30.5988 13.841 30.8468 15.7286C32.7415 15.8664 34.6868 14.7965 35.9028 13.3977Z"
        fill="white"
      />
      <path
        d="M71.5269 40.9547H68.4989L66.8402 35.873H61.0749L59.4949 40.9547H56.5469L62.2589 23.6543H65.7869L71.5269 40.9547ZM66.3402 33.741L64.8402 29.2235C64.6815 28.762 64.3842 27.6752 63.9455 25.9644H63.8922C63.7175 26.7002 63.4362 27.787 63.0495 29.2235L61.5762 33.741H66.3402Z"
        fill="white"
      />
      <path
        d="M86.2159 34.5628C86.2159 36.6844 85.6279 38.3614 84.4519 39.5925C83.3985 40.6884 82.0905 41.2357 80.5292 41.2357C78.8439 41.2357 77.6332 40.6455 76.8959 39.4651H76.8425V46.0366H73.9999V32.5855C73.9999 31.2517 73.9639 29.8828 73.8945 28.4788H76.3945L76.5532 30.4561H76.6065C77.5545 28.9663 78.9932 28.2227 80.9239 28.2227C82.4332 28.2227 83.6932 28.8038 84.7012 29.9673C85.7119 31.1321 86.2159 32.6635 86.2159 34.5628ZM83.3199 34.6642C83.3199 33.45 83.0399 32.449 82.4772 31.6612C81.8625 30.8396 81.0372 30.4288 80.0025 30.4288C79.3012 30.4288 78.6639 30.6576 78.0945 31.1087C77.5239 31.5637 77.1505 32.1578 76.9759 32.8936C76.8879 33.2368 76.8439 33.5176 76.8439 33.7386V35.8186C76.8439 36.726 77.1292 37.4917 77.6999 38.117C78.2705 38.7423 79.0119 39.0543 79.9239 39.0543C80.9945 39.0543 81.8279 38.6513 82.4239 37.8479C83.0212 37.0432 83.3199 35.9824 83.3199 34.6642Z"
        fill="white"
      />
      <path
        d="M100.933 34.5628C100.933 36.6844 100.345 38.3614 99.168 39.5925C98.116 40.6884 96.8079 41.2357 95.2466 41.2357C93.5613 41.2357 92.3506 40.6455 91.6146 39.4651H91.5613V46.0366H88.7186V32.5855C88.7186 31.2517 88.6826 29.8828 88.6133 28.4788H91.1133L91.272 30.4561H91.3253C92.2719 28.9663 93.7106 28.2227 95.6426 28.2227C97.1506 28.2227 98.4106 28.8038 99.4213 29.9673C100.428 31.1321 100.933 32.6635 100.933 34.5628ZM98.0373 34.6642C98.0373 33.45 97.7559 32.449 97.1933 31.6612C96.5786 30.8396 95.756 30.4288 94.72 30.4288C94.0173 30.4288 93.3813 30.6576 92.8106 31.1087C92.24 31.5637 91.8679 32.1578 91.6933 32.8936C91.6066 33.2368 91.5613 33.5176 91.5613 33.7386V35.8186C91.5613 36.726 91.8466 37.4917 92.4146 38.117C92.9853 38.741 93.7266 39.0543 94.6413 39.0543C95.712 39.0543 96.5453 38.6513 97.1413 37.8479C97.7386 37.0432 98.0373 35.9824 98.0373 34.6642Z"
        fill="white"
      />
      <path
        d="M117.387 36.1027C117.387 37.5743 116.863 38.7716 115.811 39.6959C114.655 40.706 113.046 41.2104 110.978 41.2104C109.068 41.2104 107.538 40.8516 106.379 40.1327L107.038 37.8226C108.286 38.5584 109.655 38.9276 111.147 38.9276C112.218 38.9276 113.051 38.691 113.65 38.2204C114.246 37.7498 114.543 37.118 114.543 36.3302C114.543 35.6282 114.298 35.0367 113.806 34.557C113.316 34.0773 112.499 33.6314 111.358 33.2193C108.251 32.0896 106.699 30.4347 106.699 28.2585C106.699 26.8363 107.243 25.6702 108.332 24.7628C109.418 23.8541 110.866 23.4004 112.676 23.4004C114.291 23.4004 115.632 23.6747 116.703 24.222L115.992 26.4814C114.992 25.951 113.862 25.6858 112.596 25.6858C111.596 25.6858 110.815 25.9263 110.255 26.4047C109.782 26.8324 109.544 27.3537 109.544 27.9712C109.544 28.655 109.815 29.2205 110.359 29.6651C110.832 30.0759 111.692 30.5205 112.94 31.0002C114.467 31.5995 115.588 32.3002 116.31 33.1036C117.028 33.9044 117.387 34.9067 117.387 36.1027Z"
        fill="white"
      />
      <path
        d="M126.784 30.5594H123.651V36.6161C123.651 38.1566 124.203 38.9262 125.31 38.9262C125.818 38.9262 126.239 38.8833 126.572 38.7975L126.651 40.9022C126.091 41.1063 125.354 41.209 124.44 41.209C123.318 41.209 122.44 40.8749 121.807 40.208C121.176 39.5398 120.859 38.4192 120.859 36.8449V30.5568H118.992V28.4768H120.859V26.1927L123.651 25.3711V28.4768H126.784V30.5594Z"
        fill="white"
      />
      <path
        d="M140.923 34.6141C140.923 36.5316 140.36 38.1059 139.237 39.337C138.06 40.6045 136.497 41.2363 134.549 41.2363C132.672 41.2363 131.177 40.6292 130.063 39.415C128.948 38.2008 128.391 36.6681 128.391 34.8208C128.391 32.8877 128.964 31.3043 130.115 30.0732C131.263 28.8408 132.812 28.2246 134.76 28.2246C136.637 28.2246 138.148 28.8317 139.288 30.0472C140.379 31.2263 140.923 32.7486 140.923 34.6141ZM137.973 34.7038C137.973 33.5533 137.721 32.5666 137.211 31.7437C136.615 30.7479 135.763 30.2513 134.659 30.2513C133.516 30.2513 132.648 30.7492 132.052 31.7437C131.541 32.5679 131.289 33.5702 131.289 34.7558C131.289 35.9063 131.541 36.893 132.052 37.7146C132.667 38.7104 133.525 39.207 134.633 39.207C135.719 39.207 136.571 38.7 137.185 37.6886C137.709 36.8501 137.973 35.853 137.973 34.7038Z"
        fill="white"
      />
      <path
        d="M150.162 30.9163C149.88 30.8656 149.58 30.8396 149.266 30.8396C148.266 30.8396 147.492 31.2075 146.948 31.9446C146.475 32.5946 146.238 33.4162 146.238 34.4081V40.9536H143.396L143.423 32.4074C143.423 30.9696 143.387 29.6605 143.316 28.4801H145.792L145.896 30.8669H145.975C146.275 30.0466 146.748 29.3862 147.396 28.8909C148.03 28.445 148.714 28.2227 149.451 28.2227C149.714 28.2227 149.951 28.2409 150.162 28.2734V30.9163Z"
        fill="white"
      />
      <path
        d="M162.875 34.1259C162.875 34.6225 162.841 35.0411 162.771 35.383H154.243C154.276 36.6154 154.688 37.5579 155.48 38.2079C156.199 38.789 157.128 39.0802 158.269 39.0802C159.532 39.0802 160.684 38.8839 161.72 38.49L162.165 40.414C160.955 40.9288 159.525 41.1849 157.876 41.1849C155.892 41.1849 154.335 40.6155 153.201 39.478C152.071 38.3405 151.504 36.813 151.504 34.8968C151.504 33.0157 152.031 31.4492 153.085 30.1999C154.189 28.8661 155.681 28.1992 157.559 28.1992C159.403 28.1992 160.799 28.8661 161.747 30.1999C162.497 31.2594 162.875 32.5698 162.875 34.1259ZM160.164 33.407C160.183 32.5854 159.997 31.8756 159.612 31.2763C159.12 30.5054 158.364 30.1206 157.347 30.1206C156.417 30.1206 155.661 30.4963 155.084 31.2503C154.611 31.8496 154.329 32.5685 154.243 33.4057H160.164V33.407Z"
        fill="white"
      />
      <path
        d="M59.6875 18.1491H58L61.0724 9.42188H63.0241L66.1009 18.1491H64.4134L62.0824 11.2116H62.0142L59.6875 18.1491ZM59.7429 14.7273H64.3452V15.9972H59.7429V14.7273Z"
        fill="white"
      />
      <path
        d="M72.2085 11.6037L69.8775 18.1491H68.1729L65.842 11.6037H67.4869L68.9911 16.4659H69.0593L70.5678 11.6037H72.2085Z"
        fill="white"
      />
      <path
        d="M74.9719 18.2812C74.5572 18.2812 74.1836 18.2074 73.8512 18.0597C73.5217 17.9091 73.2603 17.6875 73.0671 17.3949C72.8768 17.1023 72.7816 16.7415 72.7816 16.3125C72.7816 15.9432 72.8498 15.6378 72.9862 15.3963C73.1225 15.1548 73.3086 14.9616 73.5444 14.8168C73.7802 14.6719 74.0458 14.5625 74.3413 14.4886C74.6396 14.4119 74.9478 14.3565 75.266 14.3224C75.6495 14.2827 75.9606 14.2472 76.1992 14.2159C76.4379 14.1818 76.6112 14.1307 76.7191 14.0625C76.8299 13.9915 76.8853 13.8821 76.8853 13.7344V13.7088C76.8853 13.3878 76.7901 13.1392 76.5998 12.9631C76.4094 12.7869 76.1353 12.6989 75.7773 12.6989C75.3995 12.6989 75.0998 12.7813 74.8782 12.946C74.6594 13.1108 74.5117 13.3054 74.435 13.5298L72.9947 13.3253C73.1083 12.9276 73.2958 12.5952 73.5572 12.3281C73.8185 12.0582 74.1381 11.8565 74.516 11.723C74.8938 11.5866 75.3114 11.5185 75.7688 11.5185C76.0842 11.5185 76.3981 11.5554 76.7106 11.6293C77.0231 11.7031 77.3086 11.8253 77.5671 11.9957C77.8256 12.1634 78.033 12.392 78.1893 12.6818C78.3484 12.9716 78.4279 13.3338 78.4279 13.7685V18.1491H76.945V17.25H76.8938C76.8001 17.4318 76.668 17.6023 76.4975 17.7614C76.3299 17.9176 76.1183 18.044 75.8626 18.1406C75.6097 18.2344 75.3129 18.2812 74.9719 18.2812ZM75.3725 17.1477C75.6822 17.1477 75.9506 17.0866 76.1779 16.9645C76.4052 16.8395 76.5799 16.6747 76.7021 16.4702C76.8271 16.2656 76.8896 16.0426 76.8896 15.8011V15.0298C76.8413 15.0696 76.7589 15.1065 76.6424 15.1406C76.5288 15.1747 76.4009 15.2045 76.2589 15.2301C76.1168 15.2557 75.9762 15.2784 75.837 15.2983C75.6978 15.3182 75.5771 15.3352 75.4748 15.3494C75.2447 15.3807 75.0387 15.4318 74.8569 15.5028C74.6751 15.5739 74.5316 15.6733 74.4265 15.8011C74.3214 15.9261 74.2688 16.0881 74.2688 16.2869C74.2688 16.571 74.3725 16.7855 74.5799 16.9304C74.7873 17.0753 75.0515 17.1477 75.3725 17.1477Z"
        fill="white"
      />
      <path
        d="M79.979 18.1491V11.6037H81.5217V18.1491H79.979ZM80.7546 10.6747C80.5103 10.6747 80.3001 10.5938 80.1239 10.4318C79.9478 10.267 79.8597 10.0696 79.8597 9.83949C79.8597 9.60653 79.9478 9.40909 80.1239 9.24716C80.3001 9.08239 80.5103 9 80.7546 9C81.0018 9 81.212 9.08239 81.3853 9.24716C81.5614 9.40909 81.6495 9.60653 81.6495 9.83949C81.6495 10.0696 81.5614 10.267 81.3853 10.4318C81.212 10.5938 81.0018 10.6747 80.7546 10.6747Z"
        fill="white"
      />
      <path d="M84.6506 9.42188V18.1491H83.108V9.42188H84.6506Z" fill="white" />
      <path
        d="M88.1204 18.2812C87.7056 18.2812 87.332 18.2074 86.9996 18.0597C86.6701 17.9091 86.4087 17.6875 86.2156 17.3949C86.0252 17.1023 85.93 16.7415 85.93 16.3125C85.93 15.9432 85.9982 15.6378 86.1346 15.3963C86.271 15.1548 86.457 14.9616 86.6928 14.8168C86.9286 14.6719 87.1942 14.5625 87.4897 14.4886C87.788 14.4119 88.0962 14.3565 88.4144 14.3224C88.7979 14.2827 89.109 14.2472 89.3477 14.2159C89.5863 14.1818 89.7596 14.1307 89.8675 14.0625C89.9783 13.9915 90.0337 13.8821 90.0337 13.7344V13.7088C90.0337 13.3878 89.9386 13.1392 89.7482 12.9631C89.5579 12.7869 89.2837 12.6989 88.9258 12.6989C88.5479 12.6989 88.2482 12.7813 88.0266 12.946C87.8079 13.1108 87.6602 13.3054 87.5835 13.5298L86.1431 13.3253C86.2567 12.9276 86.4442 12.5952 86.7056 12.3281C86.967 12.0582 87.2866 11.8565 87.6644 11.723C88.0423 11.5866 88.4599 11.5185 88.9173 11.5185C89.2326 11.5185 89.5465 11.5554 89.859 11.6293C90.1715 11.7031 90.457 11.8253 90.7156 11.9957C90.9741 12.1634 91.1815 12.392 91.3377 12.6818C91.4968 12.9716 91.5764 13.3338 91.5764 13.7685V18.1491H90.0934V17.25H90.0423C89.9485 17.4318 89.8164 17.6023 89.646 17.7614C89.4783 17.9176 89.2667 18.044 89.011 18.1406C88.7582 18.2344 88.4613 18.2812 88.1204 18.2812ZM88.521 17.1477C88.8306 17.1477 89.0991 17.0866 89.3263 16.9645C89.5536 16.8395 89.7283 16.6747 89.8505 16.4702C89.9755 16.2656 90.038 16.0426 90.038 15.8011V15.0298C89.9897 15.0696 89.9073 15.1065 89.7908 15.1406C89.6772 15.1747 89.5494 15.2045 89.4073 15.2301C89.2653 15.2557 89.1246 15.2784 88.9854 15.2983C88.8462 15.3182 88.7255 15.3352 88.6232 15.3494C88.3931 15.3807 88.1871 15.4318 88.0053 15.5028C87.8235 15.5739 87.68 15.6733 87.5749 15.8011C87.4698 15.9261 87.4173 16.0881 87.4173 16.2869C87.4173 16.571 87.521 16.7855 87.7283 16.9304C87.9357 17.0753 88.1999 17.1477 88.521 17.1477Z"
        fill="white"
      />
      <path
        d="M93.1957 18.1491V9.42188H94.7383V12.6861H94.8022C94.8817 12.527 94.994 12.358 95.1389 12.179C95.2837 11.9972 95.4798 11.8423 95.7269 11.7145C95.9741 11.5838 96.2894 11.5185 96.6729 11.5185C97.1786 11.5185 97.6346 11.6477 98.0408 11.9062C98.4499 12.1619 98.7738 12.5412 99.0124 13.044C99.2539 13.544 99.3746 14.1577 99.3746 14.8849C99.3746 15.6037 99.2567 16.2145 99.021 16.7173C98.7852 17.2202 98.4641 17.6037 98.0579 17.8679C97.6516 18.1321 97.1914 18.2642 96.6772 18.2642C96.3022 18.2642 95.9911 18.2017 95.744 18.0767C95.4968 17.9517 95.2979 17.8011 95.1474 17.625C94.9996 17.446 94.8846 17.277 94.8022 17.1179H94.7127V18.1491H93.1957ZM94.7085 14.8764C94.7085 15.2997 94.7681 15.6705 94.8874 15.9886C95.0096 16.3068 95.1843 16.5554 95.4116 16.7344C95.6417 16.9105 95.9201 16.9986 96.2468 16.9986C96.5877 16.9986 96.8732 16.9077 97.1033 16.7259C97.3335 16.5412 97.5067 16.2898 97.6232 15.9716C97.7425 15.6506 97.8022 15.2855 97.8022 14.8764C97.8022 14.4702 97.744 14.1094 97.6275 13.794C97.511 13.4787 97.3377 13.2315 97.1076 13.0526C96.8775 12.8736 96.5906 12.7841 96.2468 12.7841C95.9173 12.7841 95.6374 12.8707 95.4073 13.044C95.1772 13.2173 95.0025 13.4602 94.8832 13.7727C94.7667 14.0852 94.7085 14.4531 94.7085 14.8764Z"
        fill="white"
      />
      <path d="M102.229 9.42188V18.1491H100.686V9.42188H102.229Z" fill="white" />
      <path
        d="M106.713 18.277C106.056 18.277 105.49 18.1406 105.012 17.8679C104.538 17.5923 104.173 17.2031 103.917 16.7003C103.662 16.1946 103.534 15.5994 103.534 14.9148C103.534 14.2415 103.662 13.6506 103.917 13.142C104.176 12.6307 104.537 12.233 105 11.9489C105.463 11.6619 106.007 11.5185 106.632 11.5185C107.035 11.5185 107.416 11.5838 107.774 11.7145C108.135 11.8423 108.453 12.0412 108.728 12.3111C109.007 12.581 109.225 12.9247 109.385 13.3423C109.544 13.7571 109.623 14.2514 109.623 14.8253V15.2983H104.258V14.2585H108.145C108.142 13.9631 108.078 13.7003 107.953 13.4702C107.828 13.2372 107.653 13.054 107.429 12.9205C107.207 12.7869 106.949 12.7202 106.653 12.7202C106.338 12.7202 106.061 12.7969 105.822 12.9503C105.583 13.1009 105.397 13.2997 105.264 13.5469C105.133 13.7912 105.066 14.0597 105.064 14.3523V15.2599C105.064 15.6406 105.133 15.9673 105.272 16.2401C105.412 16.5099 105.606 16.7173 105.856 16.8622C106.106 17.0043 106.399 17.0753 106.734 17.0753C106.958 17.0753 107.162 17.044 107.343 16.9815C107.525 16.9162 107.683 16.821 107.816 16.696C107.95 16.571 108.051 16.4162 108.119 16.2315L109.559 16.3935C109.468 16.7741 109.295 17.1065 109.039 17.3906C108.787 17.6719 108.463 17.8906 108.068 18.0469C107.673 18.2003 107.221 18.277 106.713 18.277Z"
        fill="white"
      />
      <path
        d="M116.76 18.277C116.121 18.277 115.567 18.1364 115.098 17.8551C114.629 17.5739 114.266 17.1804 114.007 16.6747C113.751 16.169 113.624 15.5781 113.624 14.902C113.624 14.2259 113.751 13.6335 114.007 13.125C114.266 12.6165 114.629 12.2216 115.098 11.9403C115.567 11.6591 116.121 11.5185 116.76 11.5185C117.399 11.5185 117.953 11.6591 118.422 11.9403C118.891 12.2216 119.253 12.6165 119.509 13.125C119.767 13.6335 119.896 14.2259 119.896 14.902C119.896 15.5781 119.767 16.169 119.509 16.6747C119.253 17.1804 118.891 17.5739 118.422 17.8551C117.953 18.1364 117.399 18.277 116.76 18.277ZM116.768 17.0412C117.115 17.0412 117.405 16.946 117.638 16.7557C117.871 16.5625 118.044 16.304 118.158 15.9801C118.274 15.6563 118.332 15.2955 118.332 14.8977C118.332 14.4972 118.274 14.1349 118.158 13.8111C118.044 13.4844 117.871 13.2244 117.638 13.0312C117.405 12.8381 117.115 12.7415 116.768 12.7415C116.413 12.7415 116.118 12.8381 115.882 13.0312C115.649 13.2244 115.474 13.4844 115.358 13.8111C115.244 14.1349 115.188 14.4972 115.188 14.8977C115.188 15.2955 115.244 15.6563 115.358 15.9801C115.474 16.304 115.649 16.5625 115.882 16.7557C116.118 16.946 116.413 17.0412 116.768 17.0412Z"
        fill="white"
      />
      <path
        d="M122.748 14.3139V18.1491H121.206V11.6037H122.68V12.7159H122.757C122.907 12.3494 123.147 12.0582 123.477 11.8423C123.809 11.6264 124.22 11.5185 124.708 11.5185C125.16 11.5185 125.554 11.6151 125.889 11.8082C126.227 12.0014 126.488 12.2813 126.673 12.6477C126.86 13.0142 126.953 13.4588 126.95 13.9815V18.1491H125.407V14.2202C125.407 13.7827 125.294 13.4403 125.066 13.1932C124.842 12.946 124.531 12.8224 124.133 12.8224C123.863 12.8224 123.623 12.8821 123.413 13.0014C123.206 13.1179 123.042 13.2869 122.923 13.5085C122.806 13.7301 122.748 13.9986 122.748 14.3139Z"
        fill="white"
      />
      <path
        d="M134.763 11.6037V12.7969H131V11.6037H134.763ZM131.929 10.0355H133.472V16.1804C133.472 16.3878 133.503 16.5469 133.566 16.6577C133.631 16.7656 133.716 16.8395 133.821 16.8793C133.926 16.919 134.043 16.9389 134.171 16.9389C134.267 16.9389 134.355 16.9318 134.435 16.9176C134.517 16.9034 134.58 16.8906 134.623 16.8793L134.882 18.0852C134.8 18.1136 134.682 18.1449 134.529 18.179C134.378 18.2131 134.194 18.233 133.975 18.2386C133.588 18.25 133.24 18.1918 132.931 18.0639C132.621 17.9332 132.375 17.7315 132.194 17.4588C132.015 17.1861 131.926 16.8452 131.929 16.4361V10.0355Z"
        fill="white"
      />
      <path
        d="M137.737 14.3139V18.1491H136.194V9.42188H137.702V12.7159H137.779C137.933 12.3466 138.17 12.0554 138.491 11.8423C138.815 11.6264 139.227 11.5185 139.727 11.5185C140.181 11.5185 140.577 11.6136 140.915 11.804C141.254 11.9943 141.515 12.2727 141.7 12.6392C141.887 13.0057 141.981 13.4531 141.981 13.9815V18.1491H140.438V14.2202C140.438 13.7798 140.325 13.4375 140.097 13.1932C139.873 12.946 139.558 12.8224 139.151 12.8224C138.879 12.8224 138.634 12.8821 138.418 13.0014C138.205 13.1179 138.038 13.2869 137.915 13.5085C137.796 13.7301 137.737 13.9986 137.737 14.3139Z"
        fill="white"
      />
      <path
        d="M146.451 18.277C145.795 18.277 145.228 18.1406 144.751 17.8679C144.276 17.5923 143.911 17.2031 143.656 16.7003C143.4 16.1946 143.272 15.5994 143.272 14.9148C143.272 14.2415 143.4 13.6506 143.656 13.142C143.914 12.6307 144.275 12.233 144.738 11.9489C145.201 11.6619 145.745 11.5185 146.37 11.5185C146.773 11.5185 147.154 11.5838 147.512 11.7145C147.873 11.8423 148.191 12.0412 148.467 12.3111C148.745 12.581 148.964 12.9247 149.123 13.3423C149.282 13.7571 149.362 14.2514 149.362 14.8253V15.2983H143.996V14.2585H147.883C147.88 13.9631 147.816 13.7003 147.691 13.4702C147.566 13.2372 147.391 13.054 147.167 12.9205C146.945 12.7869 146.687 12.7202 146.391 12.7202C146.076 12.7202 145.799 12.7969 145.56 12.9503C145.322 13.1009 145.136 13.2997 145.002 13.5469C144.871 13.7912 144.805 14.0597 144.802 14.3523V15.2599C144.802 15.6406 144.871 15.9673 145.011 16.2401C145.15 16.5099 145.344 16.7173 145.594 16.8622C145.844 17.0043 146.137 17.0753 146.472 17.0753C146.697 17.0753 146.9 17.044 147.082 16.9815C147.263 16.9162 147.421 16.821 147.555 16.696C147.688 16.571 147.789 16.4162 147.857 16.2315L149.298 16.3935C149.207 16.7741 149.033 17.1065 148.778 17.3906C148.525 17.6719 148.201 17.8906 147.806 18.0469C147.411 18.2003 146.96 18.277 146.451 18.277Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7833_128357"
          x1="87.9201"
          y1="78"
          x2="116.21"
          y2="-17.6625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EED7F1" />
          <stop offset="0.084745" stopColor="#FEFCFE" />
          <stop offset="0.191502" stopColor="#DBC1D4" />
          <stop offset="0.285358" stopColor="#6DD2E5" />
          <stop offset="0.400018" stopColor="#7BA0EB" />
          <stop offset="0.509667" stopColor="#BC86F6" />
          <stop offset="0.631842" stopColor="#DCC4F8" />
          <stop offset="0.747457" stopColor="#BFB5C6" />
          <stop offset="0.870443" stopColor="#F5F4F6" />
          <stop offset="1" stopColor="#D7CACE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
