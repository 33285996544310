import React from 'react';

import styles from './SkeletonItem.scss';
import cls from 'classnames';

type Props = {
  isDark?: boolean;
  className?: string;
  withoutAnimation?: boolean;
  specificLocator?: string;
};

export const SkeletonItem = ({
  isDark,
  className,
  withoutAnimation = false, specificLocator = ''
}: Props) => (
  <span
    className={cls(isDark ? styles.darkItem : styles.item, className, {
      [styles.withoutAnimation]: withoutAnimation,
    })}
    data-locator={`skeleton-item${specificLocator}`}
  />
);
