import superagent from '@ott/superagent';

import * as T from '../../types';

import { ERRORS } from 'components/pages/SurchargePage/constants';

const url = '/_hotels_order/public/extraCharge/pay';

type PayData =
  | {
      paySystem?: string;
      continueUrl?: string;
    }
  | undefined;

type PayResponse = {
  error: null | string;
  result: string | null;
  meta: {
    time: number;
  };
};

export default (surchargeId: string, data: PayData) => {
  return {
    types: [T.PAY_REQUEST, T.PAY_REQUEST_SUCCESS, T.PAY_REQUEST_FAIL],
    propagateError: true,
    promise: async (): Promise<string> => {
      try {
        const { body }: { body: PayResponse } = await superagent
          .post(`${url}/${surchargeId}`)
          .send(data);

        if (body.result === null) {
          return Promise.reject(ERRORS.NETWORK);
        }

        return body.result;
      } catch (error) {
        return Promise.reject(ERRORS.NETWORK);
      }
    },
  };
};
