import React from 'react';

import cls from 'classnames';

import { l10nhtml } from '@ott/l10n';

import s from './PaymentAgreementInfo.scss';

type Props = {
  className?: string;
};

export const PaymentAgreementInfo = ({ className }: Props) => {
  return (
    <div className={cls(s.PaymentAgreementInfo, className)}>
      {l10nhtml('surcharge.commonAgreement')}
    </div>
  );
};
