import { RootState } from 'src/redux/store';

import { createPayPolling, sendPayRequest } from '.';
import { setIsPaymentInProgress, setPayError } from '..';
import { getPaymentData } from '../../helpers';

import { PRODUCTS } from 'components/pages/SurchargePage/constants';

const pay = () => async (dispatch: any, getState: () => RootState) => {
  const state = getState();
  const {
    surcharge: {
      common: { currentGate, surchargeId },
    },
  } = state;

  if (!currentGate) {
    dispatch(setPayError());

    return;
  }

  try {
    const paymentData = await getPaymentData(state, PRODUCTS.HOTELS);
    await dispatch(sendPayRequest(surchargeId, paymentData));
    await dispatch(createPayPolling(surchargeId));
  } finally {
    dispatch(setIsPaymentInProgress(false));
  }
};

export default pay;
