import React, { useEffect, useLayoutEffect, useRef } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useInView } from 'react-intersection-observer';

import cls from 'classnames';

import { l10n } from '@ott/l10n';
import { useLayoutContext } from '@ott/layout-context';
import { useMessage } from '@ott/message';

import { ItemProps } from 'src/redux/modules/index/config/types';

import { Navigation } from 'src/components/blocks/SearchFormSwitcher/blocks/Navigation';

import { FlightsSearchForm } from './blocks/FlightsSearchForm';
import { HotelsSearchForm } from './blocks/HotelsSearchForm/HotelsSearchForm';

import styles from './SearchFormSwitcher.scss';

const ANCHOR_KEY = 'anchor';

export const SearchFormSwitcher = React.memo(
  ({
    activeForm,
    formCaption,
    config,
  }: ItemProps<{
    activeForm: 'avia' | 'hotels';
    formCaption: string;
  }>) => {
    const [inViewRef, isInView] = useInView({ threshold: 0.8 });

    const videoRef = useRef<HTMLVideoElement>(null);

    const messageApi = useMessage();

    const { isMobile } = useLayoutContext();

    const renderFormNode = () => {
      // eslint-disable-next-line default-case
      switch (activeForm) {
        case 'avia':
          return <FlightsSearchForm />;
        case 'hotels':
          return <HotelsSearchForm />;
      }

      return null;
    };

    const renderNav = () => {
      return <Navigation activeProduct={activeForm} />;
    };

    const renderFormBg = () => {
      return (
        <>
          <div
            className={styles.background}
            style={{
              backgroundImage: `url(${config.media?.formBg})`,
            }}
          />
          <div
            className={cls(styles.background, styles.backgroundMobile)}
            style={{
              backgroundImage: `url(${config.media?.formBgMobile})`,
            }}
          />
          <video
            ref={videoRef}
            className={styles.videoBackground}
            playsInline={true}
            muted={true}
            loop={true}
            preload="none"
          >
            <source src={isMobile ? config.media?.formVideoBgMobile : config.media?.formVideoBg} />
          </video>
          <div className={styles.shadow} />
        </>
      );
    };

    const renderFormCaption = () => {
      const title = formCaption || '';

      return <h1 dangerouslySetInnerHTML={{ __html: title }} className={styles.caption} />;
    };

    // проигрывать видео на интеракт, на девайсах на которых включен режим энергосбережения
    useEffect(() => {
      const playVideo = () => {
        if (videoRef.current && videoRef.current.paused) {
          videoRef.current.load();
          videoRef.current.play();
        }
      };

      window.addEventListener('touchstart', playVideo);
      window.addEventListener('click', playVideo);

      return () => {
        window.removeEventListener('touchstart', playVideo);
        window.removeEventListener('click', playVideo);
      };
    }, []);

    useEffect(() => {
      if (__SERVER__ || isMobile) {
        return;
      }

      if (isInView) {
        messageApi.open(
          <AnchorLink href="#onboard" className={styles.anchorLink} offset={72}>
            {l10n('anchor.scrollDown')}
          </AnchorLink>,
          {
            placement: 'bottom',
            duration: Infinity,
            key: ANCHOR_KEY,
            className: styles.message,
            childrenClassNames: {
              container: styles.messageContainer,
            },
          }
        );
      } else {
        messageApi.close(ANCHOR_KEY);
      }
    }, [isInView, messageApi, isMobile]);

    useLayoutEffect(() => {
      // Если поставим autoplay=true и preload="auto",
      // то на мобилках загрузиться видео и для десктопа и для мобилок, лишний мегабайт
      if (videoRef.current) {
        videoRef.current.load();
        videoRef.current.play();
      }
    }, [isMobile]);

    return (
      <div id="search-form-switcher" className={styles.root} ref={inViewRef}>
        {renderFormBg()}
        <div className={styles.container}>
          {renderFormCaption()}
          {renderNav()}
          {renderFormNode()}
        </div>
      </div>
    );
  }
);
