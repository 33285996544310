import { useEffect } from 'react';

import { isAuthorized, AuthData, NoAuthData } from '@ott/solar-auth';
import { Loader } from '@ott/utility-loader';

import { selectAuth } from 'src/redux/selectors/common';

import useTypedSelector from 'src/hooks/useTypedSelector';

type ReturnValue =
  | {
      isAuthorized: false;
      status: Loader;
      data: undefined | NoAuthData;
    }
  | {
      isAuthorized: true;
      status: Loader;
      data: AuthData;
    };

const useAuth = (onAuthorized?: (authData: AuthData) => void): ReturnValue => {
  const auth = useTypedSelector(selectAuth);

  const data = auth.data;

  const authorized = isAuthorized(data);

  useEffect(() => {
    if (authorized && onAuthorized) {
      onAuthorized(data);
    }
  }, [auth.data?.auth, authorized]);

  // Нужно явно указывать true false, чтобы при использовании isAuthorized в if, он работал как тайпгард для data
  if (authorized) {
    return { isAuthorized: true, status: auth.infoStatus, data };
  }

  return { isAuthorized: false, status: auth.infoStatus, data };
};

export default useAuth;
