import { useState, useEffect } from 'react';

import { lang } from '@ott/l10n';
import superagent from '@ott/superagent';

export type Deal = {
  _id: string;
  author: string;
  created: string;
  custom: any;
  image: string;
  isActive: boolean;
  label: string;
  locale: string;
  priority: number;
  product: string;
  project: string;
  subtitle: string;
  text: string;
  title: string;
  updated: string;
};

const useMarketingTexts = ({
  product,
  shouldFetch,
}: {
  product: 'avia' | 'hotels';
  shouldFetch: boolean;
}): { isLoading: boolean; result: Deal[] } => {
  const [result, setResult] = useState<Deal[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!__SERVER__ && shouldFetch) {
      setIsLoading(true);
      setResult([]);
    }
  }, [product, shouldFetch]);

  useEffect(() => {
    if (!__SERVER__ && shouldFetch) {
      superagent
        .get('/_mark/texts/')
        .query({
          project: 'solar',
          product,
          locale: lang,
        })
        .then(({ body }: { body: { result: Deal[] } }) => {
          if (body.result.length) {
            const sortResult = body.result.sort((a, b) => a.priority - b.priority);

            setResult(sortResult);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [product, shouldFetch]);

  return { isLoading, result };
};

export default useMarketingTexts;
