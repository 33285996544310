import React from 'react';
import { NavLink } from 'react-router-dom';

import cls from 'classnames';

import SolarFlightPrimary from '@ott/icon-solar-flight-primary';
import SolarStaysPrimary from '@ott/icon-solar-stays-primary';
import { lang, l10n } from '@ott/l10n';

import styles from './Navigation.scss';

type Props = {
  activeProduct: 'avia' | 'hotels';
};

const CONFIG = {
  hotels: {
    title: l10n('solar.header.hotels'),
    to: `/${lang}/hotels/`,
    icon: <SolarStaysPrimary />,
  },
  avia: {
    title: l10n('solar.header.flights'),
    to: `/${lang}/avia/`,
    icon: <SolarFlightPrimary />,
  },
};

export const Navigation = ({ activeProduct }: Props) => {
  return (
    <div className={styles.root}>
      {Object.keys(CONFIG).map((product) => {
        const options = CONFIG[product as keyof typeof CONFIG];

        return (
          <NavLink
            key={options.to}
            to={options.to}
            className={cls(styles.link, { [styles.linkActive]: product === activeProduct })}
            data-locator={options.title.match(/Stays/) ? 'hotels' : 'avia'}
          >
            <div className={styles.icon}>{options.icon}</div>
            {options.title}
          </NavLink>
        );
      })}
    </div>
  );
};
