import { addHours, isPast } from 'date-fns';

import superagent from '@ott/superagent';

import {
  ERRORS,
  CANNOT_PAY_STATUSES,
  SURCHARGE_STATUSES,
} from 'src/components/pages/SurchargePage/constants';

import { setPaymentGates, setCurrentPaymentGate } from '..';
import { PaymentGate } from '../../types';
import * as T from '../../types';
import { preparePaymentGates } from '../../utils';

const url = '/_hotels_order/public/extraCharge/link';

type SurchargeResult = {
  id: string;
  orderId: string;
  reseller: string;
  amount: number;
  currency: string;
  status: string;
  created: string;
  expirationHours: number;
  paymentGates: PaymentGate[];
};

type SurchargeResponse = {
  body: {
    error: null;
    result: SurchargeResult;
    meta: {
      time: number;
    };
  };
};

const fetchSurchargeData = (surchargeId: string) => {
  return (dispatch: any) => {
    return dispatch({
      promise: async () => {
        try {
          const { body } = (await superagent.get(`${url}/${surchargeId}`)) as SurchargeResponse;
          const { error } = body;

          if (error) {
            return Promise.reject(ERRORS.NETWORK);
          }

          const { result } = body;

          const { status } = result;

          if (CANNOT_PAY_STATUSES.includes(status)) {
            return Promise.reject(ERRORS.CANNOT_PAY);
          }

          if (status === SURCHARGE_STATUSES.SUCCESS) {
            return Promise.reject(ERRORS.PAID_EARLY);
          }

          const { expirationHours, created } = result;

          if (isPast(addHours(new Date(created), expirationHours))) {
            return Promise.reject(ERRORS.EXPIRED);
          }

          const { paymentGates } = result;

          const preparedGates = await preparePaymentGates(paymentGates);

          const [firstGate] = preparedGates;
          // выбираем первый способ оплаты как активный

          dispatch(setPaymentGates(preparedGates));
          dispatch(setCurrentPaymentGate(firstGate || null));

          return result;
        } catch (e) {
          return Promise.reject(ERRORS.NETWORK);
        }
      },
      types: [T.FETCH_SURCHARGE_DATA, T.FETCH_SURCHARGE_DATA_SUCCESS, T.FETCH_SURCHARGE_DATA_FAIL],
    });
  };
};

export default fetchSurchargeData;
