import React, { createContext, useContext, useMemo, useState } from 'react';

type LoyaltyVideoContextType = {
  isPosterVisible: boolean;
  setPosterVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LoyaltyVideoContext = createContext<LoyaltyVideoContextType>({
  isPosterVisible: false,
  setPosterVisible: () => null,
});

export const LoyaltyVideoProvider = ({ children }: { children: React.ReactNode }) => {
  const [isPosterVisible, setPosterVisible] = useState(true);

  const providerValue = useMemo(
    () => ({
      isPosterVisible,
      setPosterVisible,
    }),
    [isPosterVisible]
  );

  return (
    <LoyaltyVideoContext.Provider value={providerValue}>{children}</LoyaltyVideoContext.Provider>
  );
};

export const useLoyaltyVideoContext = () => useContext(LoyaltyVideoContext);
