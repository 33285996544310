import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { l10n, lang } from '@ott/l10n';
import formatMoney from '@ott/utility-format-money';

import { getStartPaymentActionCreator } from 'src/redux/modules/surcharge/helpers';

import { moneyFormatOptions } from '../../constants';
import commonSelector from '../../selectors/commonSelector';
import { PaymentAgreementInfo } from '../PaymentAgreementInfo/PaymentAgreementInfo';
import { BridgerPayment } from './blocks/BridgerPayment';
import { GateSwitcher } from './blocks/GateSwitcher';

import styles from './PayForm.scss';

type Props = {
  product: string;
  surchargeId: string;
};

export const PayForm: React.FC<Props> = ({ product, surchargeId }) => {
  const dispatch = useDispatch();
  const { currentGate } = useSelector(commonSelector);
  const startPaymentActionCreator = getStartPaymentActionCreator(product);
  const pay = () => dispatch(startPaymentActionCreator());

  if (!currentGate) {
    return null;
  }

  const { currency, total } = currentGate;

  const totalText = formatMoney(total, {
    lang,
    currencyCode: currency,
    numberFormatOptions: moneyFormatOptions,
  });

  return (
    <div>
      <h4 className={styles.title}>{l10n('surcharge.payForm.title')}</h4>
      <div className={styles.description}>
        {l10n('surcharge.payForm.description.hotels')} &bull; {surchargeId}
      </div>
      <GateSwitcher />
      <div className={styles.totalContainer}>
        <div>{l10n('surcharge.payForm.total')}</div>
        <div>{totalText}</div>
      </div>
      <div className={styles.submitContainer}>
        <BridgerPayment onClick={pay} surchargeId={surchargeId} />
      </div>
      <PaymentAgreementInfo />
    </div>
  );
};
