import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BridgerPay from '@ott/bridger-pay';

import { resetPayState } from 'src/redux/modules/surcharge/actions';
import { createPayPolling } from 'src/redux/modules/surcharge/actions/hotels';

import pollingSelector from 'src/components/pages/SurchargePage/selectors/pollingSelector';

import usePreviousValue from 'src/hooks/usePreviousValue';

import styles from './BridgerPayment.scss';

type Props = {
  onClick: VoidFunction;
  surchargeId: string;
};

type CashierData = {
  cashierKey: string;
  cashierToken: string;
};

export const BridgerPayment = ({ onClick, surchargeId }: Props) => {
  const dispatch = useDispatch();
  const payPolling = useSelector(pollingSelector);
  const prevPayPolling = usePreviousValue(payPolling);
  const orderProcessingResolver = useRef<((value: CashierData) => void) | null>(null);

  useEffect(() => {
    if (
      payPolling.isBridgerPay &&
      !prevPayPolling.isBridgerPay &&
      orderProcessingResolver?.current
    ) {
      const { postParams } = payPolling as { postParams: CashierData };
      orderProcessingResolver.current(postParams);
    }
  }, [payPolling, prevPayPolling]);

  const getCashierData = useCallback(async () => {
    const shouldContinuePayment: CashierData = await new Promise((resolve) => {
      orderProcessingResolver.current = resolve;
      onClick();
    });

    orderProcessingResolver.current = null;

    return shouldContinuePayment;
  }, [onClick]);

  const handlePaid = useCallback(() => {
    dispatch(createPayPolling(surchargeId));
  }, [surchargeId, dispatch]);

  const abortPayment = useCallback(() => {
    dispatch(resetPayState());
  }, [dispatch]);

  const bridgerPayComponent = useMemo(
    () => (
      <BridgerPay
        getCashierData={getCashierData}
        onPaid={handlePaid}
        onDeclined={abortPayment}
        onPaymentClose={abortPayment}
        buttonClassName={styles.bridgerPaymentButton}
      />
    ),
    [getCashierData, handlePaid, abortPayment]
  );

  return bridgerPayComponent;
};
