import { configureStore } from '@reduxjs/toolkit';

// @ts-ignore
import clientMiddleware from './middleware/client';
import createReducer from './reducers';

export const createStore = (preloadedState: any) => {
  const rootReducer = createReducer();

  const store = configureStore({
    reducer: createReducer(),
    devTools: __DEV__,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(clientMiddleware),
  });

  if (__CLIENT__) {
    window.store = store;
  }

  // @ts-ignore
  if (__DEV__ && module.hot) {
    // @ts-ignore
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>;
export type Store = ReturnType<typeof createStore>;
