import React from 'react';

import { LoyaltyMedia, LoyaltyVideo, LoyaltyVideoPoster } from '../../Loyalty';

import styles from './RewardsMedia.scss';

export const RewardsMedia = () => (
  <LoyaltyMedia>
    <div className={styles.container}>
      <div className={styles.videoWrapper}>
        <LoyaltyVideo className={styles.video}>
          <source
            src="https://static.solartrip.com/mp4/index_page/loyalty/rewards.mp4"
            type="video/mp4"
          />
          <source
            src="https://static.solartrip.com/webm/index_page/loyalty/rewards.webm"
            type="video/webm"
          />
        </LoyaltyVideo>
        <LoyaltyVideoPoster
          className={styles.poster}
          src="https://static.solartrip.com/images/index_page/loyalty/rewards_poster.png"
        />
      </div>
    </div>
  </LoyaltyMedia>
);
