import { useLocation } from 'react-router-dom';

import qs from 'qs';

function useQuery() {
  const location = useLocation();

  return qs.parse(location.search, { ignoreQueryPrefix: true });
}

export default useQuery;
