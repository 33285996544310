import { RootState } from 'src/redux/store';

import { setIsPaymentInProgress, setPayError } from '..';
import { getPayActionCreator } from '../../helpers';

const startPayment = () => (dispatch: any, getState: () => RootState) => {
  const { surcharge } = getState();
  const {
    common: { currentGate },
  } = surcharge;

  if (!currentGate) {
    dispatch(setPayError());

    return;
  }

  dispatch(setIsPaymentInProgress(true));

  const payActionCreator = getPayActionCreator('hotels');
  dispatch(payActionCreator());
};

export default startPayment;
