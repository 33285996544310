import 'src/styles/base-settings.css';
import 'intersection-observer';
import '@ott/styles/css-vars-default.scss';
import 'src/styles/solarOverwrites.scss';

import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import { createStore } from 'src/redux/store';

import Root from './Root';

const store = createStore(window.preloadedState);

const container = document.getElementById('app')!;

if (__DEV__ || __WHITE_LABEL__) {
  const root = ReactDOMClient.createRoot(container);

  root.render(<Root store={store} />);
} else {
  ReactDOMClient.hydrateRoot(container, <Root store={store} />);
}
