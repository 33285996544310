import { RootState } from 'src/redux/store';

export const selectFlightSearchFormState = (state: RootState) => {
  const passengers = {
    adultsCount: 1,
    childrenAges: [],
  };

  const route = {
    directions: [],
  };

  const serviceClass = 'E';

  const { avia: { searchForm = {} as any } = {} } = state;
  const { heatmap: { heatmap, heatmapStatus } = {} as any, suggests } = searchForm;
  const { startDate: defaultStartDate = null, endDate: defaultEndDate = null } = {};

  return {
    route,
    serviceClass,
    passengers,
    suggests,
    heatmap,
    heatmapStatus,
    defaultStartDate,
    defaultEndDate,
  };
};
