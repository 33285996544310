import _identity from 'lodash/identity';

import { l10n } from '@ott/l10n';

import { PaymentGate, PreparedGates } from 'src/redux/modules/surcharge/types';

import {
  PAYMENT_METHODS,
  PAYMENT_METHODS_ORDER,
} from 'src/components/pages/SurchargePage/constants';

const getOrder = (orderingArray: string[]) => (value: string) => {
  const index = orderingArray.indexOf(value);

  return index === -1 ? Infinity : index;
};

const sortBySortedKeys = <T>(
  array: T[],
  orderingArray: string[],
  itemToComparableValue: (item: T) => string = _identity
) => {
  const order = getOrder(orderingArray);

  return array.sort((a, b) => {
    return order(itemToComparableValue(a)) - order(itemToComparableValue(b));
  });
};

export const preparePaymentGates = async (
  serverGates?: PaymentGate[]
): Promise<PreparedGates[]> => {
  const checks = (serverGates || []).map((gate, index) => {
    const { paymentMethod: method, tag, currency, total } = gate;

    const commonParams = {
      method,
      tag,
      currency,
      total,
      value: `${tag}@${index}`,
    };

    switch (method) {
      case PAYMENT_METHODS.WIDGET: {
        return Promise.resolve({
          ...commonParams,
          title: l10n('surcharge.payForm.paymentMethod.card'),
          icons: ['mastercard', 'visa'],
        });
      }

      default:
        return Promise.reject(null);
    }
  });

  const filteredGates = (await Promise.allSettled(checks)).reduce((result, settled) => {
    if (settled.status === 'fulfilled') {
      const value = settled.value as PreparedGates;

      return [...result, value];
    }

    return result;
  }, [] as PreparedGates[]);

  return sortBySortedKeys(filteredGates, PAYMENT_METHODS_ORDER, (gate) => gate.method);
};
