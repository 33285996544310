export const PAYMENT_METHODS = {
  WIDGET: 'widget',
};

export type PaymentMethod = typeof PAYMENT_METHODS[keyof typeof PAYMENT_METHODS];

export const KNOWN_PAYMENT_METHODS: PaymentMethod[] = Object.values(PAYMENT_METHODS);
export const PAYMENT_METHODS_ORDER: PaymentMethod[] = [PAYMENT_METHODS.WIDGET];

export const SURCHARGE_STATUSES = {
  WAITING: 'waiting',
  BLOCK: 'block',
  FAIL_BLOCK: 'failBlock',
  FAIL: 'fail',
  SUCCESS: 'success',
  THREE_DS: '3ds',
  BRIDGER: 'bridger',
};

export type SurchargeStatus = typeof SURCHARGE_STATUSES[keyof typeof SURCHARGE_STATUSES];

export const API_ERRORS = {
  ORDER_CANCELLED: 'orderCancelled',
};

export type ApiError = typeof API_ERRORS[keyof typeof API_ERRORS];

export const CANNOT_PAY_STATUSES: SurchargeStatus[] = [
  SURCHARGE_STATUSES.BLOCK,
  SURCHARGE_STATUSES.FAIL,
];

export const ERRORS = {
  NETWORK: 'network',
  CANNOT_PAY: 'cannot_pay',
  PAID_EARLY: 'paid_early',
  EXPIRED: 'expired',
  FAIL_ON_PAY: 'fail_on_pay',
};

export const THREE_DS_RETURN_QUERY_PARAM = 'returned_from_acs';
export const SAVED_STATE_LS_KEY = 'stateSavedBeforeGoingToACS';

export type ErrorType = typeof ERRORS[keyof typeof ERRORS];

export const PRODUCTS = {
  HOTELS: 'hotels',
};

export type Product = typeof PRODUCTS[keyof typeof PRODUCTS];

export const KNOWN_PRODUCTS: Product[] = Object.values(PRODUCTS);

export const moneyFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export type MoneyFormatOptions = typeof moneyFormatOptions;

export const SKELETON_TIME = 1200;
