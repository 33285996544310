import React from 'react';
import { useInView } from 'react-intersection-observer';

import { l10n, l10nhtml } from '@ott/l10n';
import { useLayoutContext } from '@ott/layout-context';

import { AppStoreMobileApp } from 'src/components/icons/AppStoreMobileApp';
import { GooglePlayMobileApp } from 'src/components/icons/GooglePlayMobileApp';

import styles from './MobileApp.scss';

export const MobileApp = React.memo(() => {
  const [shouldLoadImgRef, shouldLoadImg] = useInView({ rootMargin: '200px 0px 0px 0px' });

  const { isMobile } = useLayoutContext();

  const buttonsNode = (
    <div className={styles.buttons}>
      <a
        aria-label={l10n('mobileApp.googlePlayAria')}
        href="https://slr.onelink.me/gEAA/wnabrzgw"
        target="_blank"
        rel="noreferrer noopener"
        className={styles.button}
        data-locator="google-play-button"
      >
        <GooglePlayMobileApp />
      </a>
      <a
        aria-label={l10n('mobileApp.appStoreAria')}
        href="https://slr.onelink.me/gEAA/wnabrzgw"
        target="_blank"
        rel="noreferrer noopener"
        className={styles.button}
        data-locator="app-store-button"
      >
        <AppStoreMobileApp />
      </a>
    </div>
  );

  return (
    <div className={styles.root} ref={shouldLoadImgRef} data-locator="mobile-app-block">
      <div className={styles.infoBlock}>
        <h2 className={styles.title}>{l10nhtml('mobileApp.title')}</h2>
        <p className={styles.description}>{l10nhtml('mobileApp.description')}</p>
        {!isMobile && buttonsNode}
      </div>
      <div className={styles.imageGradientContainer}>
        <div className={styles.imageShadow}>
          <img
            loading={shouldLoadImg ? 'eager' : 'lazy'}
            className={styles.image}
            alt={l10n('mobileApp.imageAlt')}
            src={
              isMobile
                ? 'https://static.solartrip.com/images/index_page/app_mobile.webp'
                : 'https://static.solartrip.com/images/index_page/app.webp'
            }
          />
        </div>
        {isMobile && buttonsNode}
      </div>
    </div>
  );
});
