import React from 'react';

import {
  form as SearchForm,
  getFlightsSearchQueryParams,
  actions,
  searchHistoryLocalStorage,
  parseRoute,
} from '@ott/flights-search-form';
import { lang } from '@ott/l10n';
import { useLayoutContext } from '@ott/layout-context';
import { saveSearchRouteInLocalStorage } from '@ott/search-history';

import { selectFlightSearchFormState } from 'src/redux/selectors/flightsSearchForm';

import { metrics } from 'src/utility/metrics';

import useTypedSelector from 'src/hooks/useTypedSelector';

import styles from './FlightsSearchForm.scss';

export const FlightsSearchForm = React.memo(() => {
  const {
    route,
    serviceClass,
    passengers,
    suggests,
    heatmap,
    heatmapStatus,
    defaultStartDate,
    defaultEndDate,
  } = useTypedSelector(selectFlightSearchFormState);

  const layoutContext = useLayoutContext();

  const handleFormSubmit = (data: any) => {
    metrics('start_solar_search');

    if (data.url) {
      saveSearchRouteInLocalStorage(
        data.route,
        'historyRoutes',
        3,
        searchHistoryLocalStorage.checkNewSearchRoute
      );

      window.location.href = data.url;

      return;
    }

    const { route: queryRoute, query } = getFlightsSearchQueryParams(data);

    const nextQuery = {
      ...query,
      // srcmarker2: 'solar_index',
    };

    const queryString = Object.keys(nextQuery)
      .map((key) => `${key}=${nextQuery[key]}`)
      .join('&');

    saveSearchRouteInLocalStorage(
      {
        route: parseRoute(queryRoute),
        query,
      },
      'historyRoutes',
      3,
      searchHistoryLocalStorage.checkNewSearchRoute
    );

    window.location.href = `${window.location.origin}/${lang}/avia/search/${queryRoute}${
      queryString ? `?${queryString}` : ''
    }`;
  };

  return (
    <SearchForm
      route={route}
      className={styles.root}
      serviceClass={serviceClass}
      passengers={passengers}
      suggests={suggests}
      heatmap={heatmap}
      heatmapStatus={heatmapStatus}
      withHeatmap={false}
      showMonthsLabels={false}
      isMobile={false}
      deviceInfo={layoutContext}
      actions={actions}
      onFormSubmit={handleFormSubmit}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
      enableInitialFocus={false}
      searchUrlPathname={`/${lang}/avia/search`}
    />
  );
});
