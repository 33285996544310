import React from 'react';

import { stringify, parse as qsParse, ParsedQs } from 'qs';

import { Deal } from 'src/hooks/useMarketingTexts';
import useQuery from 'src/hooks/useQuery';

import styles from '../../Deals.scss';

type Props = {
  deal: Deal;
};

export const HotelsDeal = React.memo(({ deal }: Props) => {
  const query = useQuery();

  const splittedUrl = deal?.custom?.url?.split('?') || '';
  const queryParams: ParsedQs = qsParse(splittedUrl[1]);

  queryParams.date_start = query.date_start;
  queryParams.date_end = query.date_end;
  queryParams.timezone_gmt = String(new Date().getTimezoneOffset() / -60);

  return (
    <a
      key={deal.title}
      className={styles.deal}
      href={`${splittedUrl[0]}?${stringify(queryParams)}`}
      target="_blank"
      rel="noopener noreferrer"
      data-locator={`deal-${deal.title.toLowerCase()}`}
    >
      <div className={styles.dealName}>{deal.title}</div>
      <img className={styles.dealImage} src={deal.image} alt={deal.title} />
    </a>
  );
});
